import clsx from "clsx";
import { useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import approvedSourcesSorting from "../../../../img/approved-sources/approvedSourcesSorting.gif";
import {
  AgencyTypeEnum,
  ApiService,
  type ApprovedSource,
  ApprovedSourceStatusEnum,
  type BuyerLeadAgency,
  SourceAgencyGroupEnum,
} from "../../../generated";
import { Button, Typography } from "../../../library";
import { popupState } from "../../../recoil/page";
import { buyerProfileState } from "../../../recoil/user";
import SupportEmailLink from "../../../shared/SupportEmailLink";
import { getParam } from "../../../utils";
import { bgColorClass } from "../../../utils/colors";
import { CONTRACT_EMAIL_ADDRESS, stateOptions } from "../../../utils/constants";
import { PopupType } from "../../../utils/enums";
import { handleError } from "../../../utils/generatedApi";
import {
  type TrackApprovedSourceParams,
  trackAddApprovedSourceGroup,
  trackAddNewApprovedSource,
  trackCheckShowApprovedSourcesFirst,
  trackRemoveApprovedSource,
  trackRemoveApprovedSourceGroup,
  trackUpdateApprovedSourceStatus,
} from "../../../utils/tracking";
import BLAAutocompleteInput from "../../BLAAutocompleteInput";
import ContactBox from "../ContactBox";
import PreferenceCheckbox from "../PreferenceCheckbox";
import ApprovedSourcesTable from "./ApprovedSourcesTable";

export default function AdminApprovedSources() {
  const [buyerProfile, setBuyerProfile] = useRecoilState(buyerProfileState);
  const [approvedSources, setApprovedSources] = useState<ApprovedSource[]>([]);
  const [approvedSourceAgencyGroupEnums, setApprovedSourceAgencyGroupEnums] =
    useState<Record<SourceAgencyGroupEnum, Maybe<ApprovedSource>>>({
      [SourceAgencyGroupEnum.ALL_NATIONAL_COOP]: null,
      [SourceAgencyGroupEnum.ALL_STATE_SOURCE]: null,
      [SourceAgencyGroupEnum.ALL_ENTITY_TYPE_SOURCE]: null,
    });
  const setPopupState = useSetAtom(popupState);
  const [showAddSourceFromParam, setShowAddSourceFromParam] = useState(false);
  const sourceIdParam = getParam("sourceId");
  const sourceNameParam = getParam("sourceName");

  useEffect(() => {
    (async () => {
      try {
        const response = await ApiService.apiV1ApprovedSourcesList();
        setApprovedSources(response);
      } catch (err) {
        handleError(err);
        // setError("Unable to add public entity. Please try again.");
      }
    })();
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: should not include approvedSourceAgencyGroupEnums
  useEffect(() => {
    const approvedSourceAgencyGroupEnumsUpdate = approvedSourceAgencyGroupEnums;
    const allNationalCoopItem = approvedSources.find(
      ({ sourceAgencyGroupType }) =>
        sourceAgencyGroupType === AgencyTypeEnum.NATIONAL_COOPERATIVE
    );
    if (allNationalCoopItem) {
      approvedSourceAgencyGroupEnumsUpdate[
        SourceAgencyGroupEnum.ALL_NATIONAL_COOP
      ] = allNationalCoopItem;
    }
    const allStateSourceItem = approvedSources.find(
      ({ sourceAgencyGroupState }) => !!sourceAgencyGroupState
    );
    if (allStateSourceItem) {
      approvedSourceAgencyGroupEnumsUpdate[
        SourceAgencyGroupEnum.ALL_STATE_SOURCE
      ] = allStateSourceItem;
    }
    const allEntityTypeItem = approvedSources.find(
      ({ sourceAgencyGroupType }) =>
        sourceAgencyGroupType === buyerProfile?.governmentAgency?.agencyType
    );
    if (allEntityTypeItem) {
      approvedSourceAgencyGroupEnumsUpdate[
        SourceAgencyGroupEnum.ALL_ENTITY_TYPE_SOURCE
      ] = allEntityTypeItem;
    }
    setApprovedSourceAgencyGroupEnums((prev) => ({
      ...prev,
      ...approvedSourceAgencyGroupEnumsUpdate,
    }));
  }, [approvedSources, buyerProfile]);

  useEffect(() => {
    if (!sourceIdParam || !sourceNameParam) return;
    if (
      approvedSources.length &&
      approvedSources.filter(({ sourceId }) => sourceId === sourceIdParam)
        .length === 0
    ) {
      setShowAddSourceFromParam(true);
    }
  }, [sourceIdParam, sourceNameParam, approvedSources]);

  const toggleApprovedSourceAgencyGroupEnum = async ({
    origin,
    sourceGroup,
  }: {
    origin: "toggle" | "tableRowMenu";
    sourceGroup: SourceAgencyGroupEnum;
  }) => {
    try {
      let updatedApprovedSources: ApprovedSource[];
      let updatedApprovedSourceAgencyGroupEnums: Partial<
        Record<SourceAgencyGroupEnum, Maybe<ApprovedSource>>
      >;

      const approvedSourceItem = approvedSourceAgencyGroupEnums[sourceGroup];
      if (approvedSourceItem) {
        const { id, sourceName, sourceTypeLabel } = approvedSourceItem;
        await ApiService.apiV1ApprovedSourcesDestroy(id);

        updatedApprovedSources = approvedSources.filter(
          (source) => source.id !== id
        );
        updatedApprovedSourceAgencyGroupEnums = { [sourceGroup]: null };
        trackRemoveApprovedSourceGroup({
          origin,
          sourceName,
          sourceTypeLabel,
        });
      } else {
        const request = {
          sourceAgencyId: null,
          sourceAgencyGroup: sourceGroup,
        };

        // Optimistically update group enums so that the checkbox is on
        setApprovedSourceAgencyGroupEnums((prev) => ({
          ...prev,
          [sourceGroup]: { id: 0 },
        }));

        const response = await ApiService.apiV1ApprovedSourcesCreate(request);

        updatedApprovedSources = [...approvedSources, response];
        updatedApprovedSourceAgencyGroupEnums = {
          [sourceGroup]: response,
        };
        const { sourceName, sourceTypeLabel } = response;
        trackAddApprovedSourceGroup({
          sourceName,
          sourceTypeLabel,
        });
      }
      // Auto-enable sorting preference when user adds the entity's first approved source
      if (
        buyerProfile.governmentAgency &&
        !buyerProfile.governmentAgency.showOnlyApprovedSources &&
        approvedSources.length === 0
      ) {
        onChangePreferences("", true);
      }
      setApprovedSources(updatedApprovedSources);
      setApprovedSourceAgencyGroupEnums((prev) => ({
        ...prev,
        ...updatedApprovedSourceAgencyGroupEnums,
      }));
    } catch (err) {
      handleError(err);
      // setError("Unable to add public entity. Please try again.");
    }
  };

  const onAddSource = async (
    _name: string | null,
    agency: BuyerLeadAgency | null,
    { clear }: { clear: () => void }
  ) => {
    if (!agency) return;
    try {
      const response = await ApiService.apiV1ApprovedSourcesCreate({
        sourceAgencyId: agency.id,
        sourceAgencyGroup: null,
      });

      const { sourceName, sourceTypeLabel, sourceId } = response;
      clear();

      // Auto-enable sorting preference when user adds the entity's first approved source
      if (
        buyerProfile.governmentAgency &&
        !buyerProfile.governmentAgency.showOnlyApprovedSources &&
        approvedSources.length === 0
      ) {
        onChangePreferences("", true);
      }

      setApprovedSources([...approvedSources, response]);
      trackAddNewApprovedSource({
        sourceName,
        sourceTypeLabel,
        sourceId,
      });
    } catch (err) {
      handleError(err);
      // setError("Unable to add public entity. Please try again.");
    }
  };

  const addSourceFromParam = async () => {
    try {
      const response = await ApiService.apiV1ApprovedSourcesCreate({
        sourceAgencyId: sourceIdParam,
        sourceAgencyGroup: null,
      });

      const { sourceName, sourceTypeLabel, sourceId } = response;

      // Auto-enable sorting preference when user adds the entity's first approved source
      if (
        buyerProfile.governmentAgency &&
        !buyerProfile.governmentAgency.showOnlyApprovedSources &&
        approvedSources.length === 0
      ) {
        onChangePreferences("", true);
      }

      setApprovedSources([...approvedSources, response]);
      trackAddNewApprovedSource({
        sourceName,
        sourceTypeLabel,
        sourceId,
        fromParam: true,
      });

      setShowAddSourceFromParam(false);
      setPopupState({
        analyticsClassName:
          "analytics-approved-sources-source-from-param-added-popup",
        name: PopupType.SUCCESS,
        durationSeconds: 5,
        children: <Typography color="inverse">"{sourceName}" added</Typography>,
        show: true,
      });
    } catch (err) {
      handleError(err);
    }
  };

  const onRemoveSource = async (
    id: string,
    trackingParams: TrackApprovedSourceParams
  ) => {
    try {
      await ApiService.apiV1ApprovedSourcesDestroy(id);
      const update = approvedSources.filter((source) => source.id !== id);
      setApprovedSources(update);

      const sourceGroup = Object.keys(approvedSourceAgencyGroupEnums).find(
        (key) => {
          const idx = key as SourceAgencyGroupEnum;
          return approvedSourceAgencyGroupEnums[idx]?.id === id;
        }
      );
      if (sourceGroup) {
        setApprovedSourceAgencyGroupEnums({
          ...approvedSourceAgencyGroupEnums,
          [sourceGroup]: null,
        });
      }
      trackRemoveApprovedSource(trackingParams);
    } catch (err) {
      handleError(err);
    }
  };

  const onChangePreferences = async (_: string, checked: boolean) => {
    if (!buyerProfile.governmentAgency) return;
    try {
      await ApiService.apiV1BuyerLeadAgenciesPartialUpdate(
        buyerProfile.governmentAgency.id,
        { showOnlyApprovedSources: checked }
      );

      const buyerProfileUpdate = {
        ...buyerProfile,
        governmentAgency: {
          ...buyerProfile.governmentAgency,
          showOnlyApprovedSources: checked,
        },
      };
      setBuyerProfile(buyerProfileUpdate);
      trackCheckShowApprovedSourcesFirst({
        checked,
      });
    } catch (err) {
      handleError(err);
    }
  };

  const updateApprovedSourceStatus = async (
    id: string,
    status: ApprovedSourceStatusEnum
  ) => {
    try {
      const updatedSource = await ApiService.apiV1ApprovedSourcesUpdate(id, {
        status,
      });
      let update: ApprovedSource[];
      if (status === ApprovedSourceStatusEnum.REJECTED) {
        update = approvedSources.filter((source) => source.id !== id);
      } else {
        update = approvedSources.map((source) => {
          return source.id !== id ? source : updatedSource;
        });
      }
      trackUpdateApprovedSourceStatus({
        sourceName: updatedSource.sourceName,
        sourceTypeLabel: updatedSource.sourceTypeLabel,
        sourceId: updatedSource.sourceId,
        newStatus: status,
      });
      setApprovedSources(update);
    } catch (err) {
      handleError(err);
    }
  };

  const agencyName = buyerProfile?.governmentAffiliationDisplayName;
  const stateName = stateOptions.find(
    ({ value }) => value === buyerProfile?.governmentAgency?.stateCode
  );
  const showEntityCheckbox =
    !!buyerProfile?.governmentAgency?.agencyType &&
    buyerProfile.governmentAgency.agencyType !== AgencyTypeEnum.OTHER;

  return (
    <div className="flex flex-col gap-10 md:gap-16 md:grid md:grid-cols-9 md:gap-x-6">
      <div className="flex flex-col md:flex-row md:grid md:grid-cols-9 md:col-span-9 md:gap-x-6">
        <div className="grid gap-8 mb-4 md:mb-0 md:col-span-6">
          {showAddSourceFromParam && (
            <div
              className={clsx(
                "flex flex-col rounded-3 p-6 gap-4",
                bgColorClass.brand.subtler.enabled
              )}
            >
              <Typography variant="body">
                Add <strong>{sourceNameParam}</strong> as a new approved source?
              </Typography>
              <div className="flex gap-2">
                <Button
                  size={Button.sizes.SMALL}
                  theme={Button.themes.PRIMARY_DARK}
                  onClick={addSourceFromParam}
                >
                  Confirm
                </Button>
                <Button
                  size={Button.sizes.SMALL}
                  theme={Button.themes.TERTIARY_DARK}
                  onClick={() => setShowAddSourceFromParam(false)}
                >
                  Dismiss
                </Button>
              </div>
            </div>
          )}
          <div className="grid gap-2">
            <Typography
              variant="headline"
              size="sm"
              color="brand.boldest.enabled"
              emphasis
            >
              Manage contract sources
            </Typography>
            <Typography color="neutral.bolder.enabled">
              Customize your team's search experience by selecting approved
              contract sources. Contracts from these sources will show up at the
              top of search results for{" "}
              <span className="font-semibold">all team members</span>.
            </Typography>
          </div>
          <div className="flex flex-col gap-3">
            <PreferenceCheckbox
              name="addNationalCooperatives"
              analyticsClassName="analytics-approved-sources-allCoop"
              label="Add all national cooperatives"
              onChange={() =>
                toggleApprovedSourceAgencyGroupEnum({
                  origin: "toggle",
                  sourceGroup: SourceAgencyGroupEnum.ALL_NATIONAL_COOP,
                })
              }
              checked={
                !!approvedSourceAgencyGroupEnums[
                  SourceAgencyGroupEnum.ALL_NATIONAL_COOP
                ]
              }
              size="none"
            />
            <PreferenceCheckbox
              name="addStateSources"
              analyticsClassName="analytics-approved-sources-allState"
              label={`Add all local and regional sources in ${stateName?.label}`}
              onChange={() =>
                toggleApprovedSourceAgencyGroupEnum({
                  origin: "toggle",
                  sourceGroup: SourceAgencyGroupEnum.ALL_STATE_SOURCE,
                })
              }
              checked={
                !!approvedSourceAgencyGroupEnums[
                  SourceAgencyGroupEnum.ALL_STATE_SOURCE
                ]
              }
              size="none"
            />
            {showEntityCheckbox && (
              <PreferenceCheckbox
                name="addEntityTypeSources"
                analyticsClassName="analytics-approved-sources-allEntityType"
                label={`Add all ${buyerProfile?.governmentAgency?.agencyTypeLabel?.toLocaleLowerCase()} sources`}
                onChange={() =>
                  toggleApprovedSourceAgencyGroupEnum({
                    origin: "toggle",
                    sourceGroup: SourceAgencyGroupEnum.ALL_ENTITY_TYPE_SOURCE,
                  })
                }
                checked={
                  !!approvedSourceAgencyGroupEnums[
                    SourceAgencyGroupEnum.ALL_ENTITY_TYPE_SOURCE
                  ]
                }
                size="none"
              />
            )}
          </div>
          <div className="relative">
            <BLAAutocompleteInput
              analyticsClass="analytics-approved-sources-input"
              label="Add a cooperative, state, or local agency"
              sublabel="Start typing to see contract sources"
              onChange={onAddSource}
              errorMessage={""}
              useAddComponent
              freeSolo={false}
              excludeCooperatives={false}
            />
          </div>
        </div>
        <div className="md:col-span-3">
          <ContactBox
            headline="Can't find the contract source you're looking for?"
            body={
              <>
                Let us know by emailing{" "}
                <SupportEmailLink
                  className="analytics-click-pro-supplier-bulk-contract-email"
                  underline
                  email={CONTRACT_EMAIL_ADDRESS}
                />
                .
              </>
            }
          />
        </div>
      </div>
      <div className="grid gap-8 md:col-span-9">
        <Typography
          variant="headline"
          size="sm"
          color="brand.boldest.enabled"
          emphasis
        >
          Approved sources for {agencyName}
        </Typography>
        <ApprovedSourcesTable
          sources={approvedSources}
          onRemoveSource={onRemoveSource}
          updateApprovedSourceStatus={updateApprovedSourceStatus}
          allowEdits
        />
      </div>
      <div className="md:grid md:col-span-9 md:grid-cols-9 md:gap-6">
        <div className="md:col-span-6">
          <Typography
            variant="headline"
            size="sm"
            color="brand.boldest.enabled"
            emphasis
          >
            How do you want results to appear to your team in search?
          </Typography>
          <PreferenceCheckbox
            size="lg"
            name="sortApprovedSourcesFirst"
            analyticsClassName="analytics-approved-sources-sorting-preferences"
            label="Show contracts from approved sources first in search results for all team members"
            onChange={onChangePreferences}
            checked={!!buyerProfile?.governmentAgency?.showOnlyApprovedSources}
          />
        </div>
        <img
          className="hidden md:block md:col-span-3"
          src={approvedSourcesSorting}
        />
      </div>
    </div>
  );
}
