import { TagVariants } from "../shared/Tag";
import { isFeatureEnabled } from "./split";

export const supplierTagOrderDefault: TagVariants[] = [
  TagVariants.PRO_SUPPLIER,
  TagVariants.VERIFIED_CONTACT,
  TagVariants.LOCAL_SUPPLIER,
];

export const supplierTagOrderSERP: TagVariants[] = [
  TagVariants.LOCAL_SUPPLIER,
  TagVariants.VERIFIED_CONTACT,
  TagVariants.BEST_PRICE_GUARANTEE,
  TagVariants.YEARS_IN_BUSINESS,
  TagVariants.MAX_DELIVERY_TIME,
  TagVariants.SERVICE_PROVIDED,
  TagVariants.FREE_CONSULTATION,
  TagVariants.MADE_IN_USA,
  TagVariants.FAMILY_OWNED,
  TagVariants.GOVERNMENT_DISCOUNT,
  // TODO: Restore this after sc-24106 is completed
  // https://app.shortcut.com/coprocure/story/24106/m2-move-1-ranked-on-contract-badge-to-be-on-contract-model
  // TagVariants.RANKED_FIRST_ON_CONTRACT,
];

export function getSupplierTagOrder(): TagVariants[] {
  return isFeatureEnabled("redesignedSERPCards")
    ? supplierTagOrderSERP
    : supplierTagOrderDefault;
}
