import ArrowBack from "@mui/icons-material/ArrowBack";
import { type ReactNode, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";

import { useAtom } from "jotai";
import { titleCase } from "title-case";
import { ItemTypeEnum } from "../../generated";
import useShowModal from "../../hooks/useShowModal";
import { Badge, Button, Link } from "../../library";
import CopyableText from "../../library/CopyableText";
import type { TypographyColor } from "../../library/Typography/types";
import type { ShowBuyerOptInSaveContractModal } from "../../modals/BuyerOptInModals/BuyerOptinSaveContractModal";
import type { ContractSolicitationDetails } from "../../pages/Contract";
import { AnimatedPopupTypes, SuccessPopup } from "../../popups/AnimatedPopup";
import { optedInOutreachSuppliersAtom } from "../../recoil/history";
import {
  piggybackedContractsLoadingState,
  piggybackedContractsState,
} from "../../recoil/piggyback";
import { profileTypeState, userState, userStateState } from "../../recoil/user";
import PendingContractText from "../../shared/PendingContractText";
import SaveToProjectButton from "../../shared/SaveToProjectButton";
import { getParam } from "../../utils";
import {
  MODAL_SOURCE,
  ProfileType,
  modals,
  pageNavigationSourceTypes,
} from "../../utils/enums";
import { supplierHasFeature } from "../../utils/featureManagement";
import {
  trackContractNumberClick,
  trackContractTitleClick,
  trackSupplierOutreachOptInSaveContract,
  trackSupplierOutreachOptOutSaveContract,
} from "../../utils/tracking";
import usePiggybackedContracts from "../EntityContractsPage/PiggybackedContracts/usePiggybackedContracts";
import { ProjectCreationSource } from "../Project/types";
import ShareContractButton from "./ShareContractButton";

interface SolicitationPageHeaderProps {
  title: string;
  query: string;
  contractDetails: ContractSolicitationDetails;
  solicitationId: Maybe<string>;
  children?: ReactNode;
}

export default function SolicitationPageHeader({
  title,
  query,
  contractDetails,
  solicitationId,
  children,
}: SolicitationPageHeaderProps) {
  const profileType = useRecoilValue(profileTypeState);
  const piggybackedContracts = useRecoilValue(piggybackedContractsState);
  const isPiggybackLoading = useRecoilValue(piggybackedContractsLoadingState);
  const { supplierContactOptIn } = useRecoilValue(userStateState);
  const [optedInOutreachSuppliers, setOptedInOutreachSuppliers] = useAtom(
    optedInOutreachSuppliersAtom
  );
  const showBuyerOptInSaveToProjectModal: ShowBuyerOptInSaveContractModal =
    useShowModal(modals.BUYER_OPT_IN_SAVE_CONTRACT_MODAL);

  const {
    piggybackOnContract,
    removePiggybackedContract,
    addPiggybackSuccessPopup,
    removePiggybackSuccessPopup,
    piggybackErrorPopup,
  } = usePiggybackedContracts();

  const piggybackedContract = useMemo(
    () =>
      piggybackedContracts.find(
        (contract) => contract.docid === contractDetails.contract_id
      ),
    [piggybackedContracts, contractDetails.contract_id]
  );

  const piggybackedContractId = piggybackedContract?.id;
  // allow piggybacking on/off if the contract is not piggybacked on yet or
  // if the user has edit permissions on the piggybacked contract
  const canEditPiggybackContract =
    !piggybackedContractId ||
    piggybackedContract?.isDeletable ||
    isPiggybackLoading;

  const piggybackItem = {
    label: piggybackedContract
      ? "Remove from piggybacked contracts"
      : "Add to piggybacked contracts",
    // sm:font-semibold is used to override the font weight of the dropdown item
    className:
      "analytics-save-project-dropdown-piggyback w-9/10 mx-auto border-t-1 border-solid border-cp-neutral-200 mt-2 pt-4 font-semibold sm:font-semibold",
    onClick: handleTogglePiggyback,
    color: "brand.bold.enabled" as TypographyColor,
  };

  const { contract_number, contract_id, supplier } = contractDetails;

  const [showContractSharedPopup, setShowContractSharedPopup] = useState(false);

  const { supplier: supplierState, isAdmin } = useRecoilValue(userState);

  const _trackContractNumberClick = () => {
    trackContractNumberClick({
      contractId: contract_id,
      supplierId: supplier.id,
      supplierHandle: supplier.handle,
      contractNumber: contract_number,
    });
  };

  const _trackContractTitleClick = () => {
    trackContractTitleClick({
      contractId: contract_id,
      supplierId: supplier.id,
      supplierHandle: supplier.handle,
      contractNumber: contract_number,
      contractTitle: title,
    });
  };

  function handleTogglePiggyback() {
    const contract = {
      contractId: contractDetails.contract_id,
      solicitationId: solicitationId || "",
      supplierId: contractDetails.supplier.id,
      supplierHandle: contractDetails.supplier.handle,
    };
    if (piggybackedContractId) {
      void removePiggybackedContract({
        id: piggybackedContractId,
        contract,
      });
    } else {
      void piggybackOnContract(contract);
    }
  }
  function showOutreachModal({ onClickCta }: { onClickCta: () => void }) {
    if (
      supplierContactOptIn &&
      profileType === ProfileType.BUYER &&
      !optedInOutreachSuppliers.includes(supplier.id)
    ) {
      setOptedInOutreachSuppliers([...optedInOutreachSuppliers, supplier.id]);
      showBuyerOptInSaveToProjectModal({
        supplier,
        contractId: contract_id,
        contractTitle: title,
        solicitationId: solicitationId || "",
        query,
        supplierPOC: contractDetails.supplier_contact?.full_name || "",
        source: MODAL_SOURCE.SAVE_CONTRACT,
        onComplete: onClickCta,
        onSkip: onClickCta,
        trackOptIn: trackSupplierOutreachOptInSaveContract,
        trackOptOut: trackSupplierOutreachOptOutSaveContract,
        modalType: "BUYER_OPT_IN_SAVE_CONTRACT_MODAL",
      });
    } else {
      onClickCta();
    }
  }

  return (
    <>
      <div className="flex flex-col justify-between w-full">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-4 justify-between">
            <div className="flex flex-col gap-2">
              {(supplierHasFeature(
                supplierState?.activeAgreements,
                "contractManagement"
              ) ||
                isAdmin) &&
                getParam("pageNavigationSource") ===
                  pageNavigationSourceTypes.SUPPLIER_EDIT_PAGE && (
                  <Link
                    href={`/suppliers/${contractDetails.supplier.handle}/edit#contracts`}
                    underline={false}
                    newWindow={false}
                    className="pb-4"
                    size="sm"
                  >
                    <Badge Icon={ArrowBack}>Back to manage contracts</Badge>
                  </Link>
                )}
              {/*contract number*/}
              {contract_number && (
                <CopyableText
                  textToCopy={contract_number}
                  onClick={_trackContractNumberClick}
                  onCopy={_trackContractNumberClick}
                  color="neutral.bolder.enabled"
                  size="sm"
                  analyticsClassName="analytics-solicitation-contract-number"
                  showIconOnlyOnHover={false}
                >
                  {`Contract # ${contract_number}`}
                </CopyableText>
              )}
              <div className="flex flex-row gap-0">
                <CopyableText
                  variant="headline"
                  color="brand.boldest.enabled"
                  emphasis
                  className="right-0"
                  analyticsClassName="analytics-solicitation-contract-title"
                  onClick={_trackContractTitleClick}
                  onCopy={_trackContractTitleClick}
                >
                  {titleCase(title || "")}
                </CopyableText>
                <PendingContractText
                  numPendingDocs={
                    contractDetails.contract_edit_request_data?.numPendingDocs
                  }
                  variant="headline"
                />
              </div>
            </div>
            {children}
            <div className="flex gap-4">
              {profileType === ProfileType.BUYER && (
                <SaveToProjectButton
                  itemId={contractDetails.contract_id}
                  itemType={ItemTypeEnum.CONTRACT}
                  source={ProjectCreationSource.CONTRACT_PAGE}
                  additionalItems={
                    canEditPiggybackContract ? [piggybackItem] : []
                  }
                  showOutreachModal={showOutreachModal}
                />
              )}
              <ShareContractButton
                showContractSharedPopup={() => setShowContractSharedPopup(true)}
                contractId={contractDetails.contract_id}
                supplierId={contractDetails.supplier.id}
                supplierHandle={contractDetails.supplier.handle}
                contractNumber={contractDetails.contract_number}
                contractTitle={title}
              />
              {isAdmin && (
                <Button
                  as={Link}
                  theme={Button.themes.SECONDARY_DARK}
                  size={Button.sizes.SMALL}
                  linkProps={{
                    href: `https://coprocure.retool.com/app/contract-admin#id=${contractDetails.contract_id}`,
                    underline: false,
                  }}
                  className="h-fit"
                >
                  😺 Admin
                </Button>
              )}
              {addPiggybackSuccessPopup}
              {removePiggybackSuccessPopup}
              {piggybackErrorPopup}
              <SuccessPopup
                type={AnimatedPopupTypes.TIMED}
                show={showContractSharedPopup}
                setShow={setShowContractSharedPopup}
              >
                Contract shared!
              </SuccessPopup>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
