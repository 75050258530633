import _get from "lodash/get";
import { createRoot } from "react-dom/client";

import SolicitationPageContainer from "../components/SolicitationPage/SolicitationPageContainer";
import type { ContractFile } from "../components/SolicitationPage/types";
import { getDOMAnchorById } from "../utils";
import { type ContractTypes, PageType } from "../utils/enums";

import type {
  BaseContract,
  BaseSupplier,
  ContractScopeTypeEnum,
  FrontendTag,
  ProQualifications,
  Renewal,
  SupplierAgreementData,
  SupplierCompliance,
  SupplierDisplay,
  SupplierServiceAreaData,
} from "../generated";
import type {
  ContractDocumentData,
  ContractEditRequestData,
} from "../shared/ContractBase/types";
import type { SupplierContact } from "../shared/types";
import Page, { type AuthPageProps } from "./Page/Page";

export interface DiversityCertification {
  coverage: string;
  group: string;
  name: string;
  short_name: string;
  types: string[];
}

export interface ISolicitation {
  cooperative_affiliation: string;
  cooperative_affiliation_id: string;
  cooperative_affiliation_url: string;
  buyer_lead_agency: string;
  buyer_lead_agency_id: string;
  title: string;
  contract_details: ContractSolicitationDetails;
  buyer_lead_agency_url: string;
  num_reseller_contracts: number;
  reseller_contracts: BaseContract[];
  num_other_contracts: number;
  other_contracts: BaseContract[];
  cooperative_affiliation_membership_url: string;
  cooperative_affiliation_membership_notes: string;
  cooperative_usage_summary: string;
  cooperative_usage_url: string;
}

// Using generated types

export interface ContractSolicitationDetails {
  auto_extracted_offerings: string[];
  bla_poc: string;
  brands: string[];
  catalog_url: Maybe<string>;
  contract_id: string;
  contract_number: string;
  contract_source_flag: Maybe<string>;
  contract_type: ContractTypes;
  cooperative_language: boolean | null;
  expiration_date: Maybe<string | Date>;
  expiration_without_renewals_date: Maybe<string | Date>;
  final_expiration_date: Maybe<string | Date>;
  file_information: ContractFile[];
  offerings: string[];
  source_url: string;
  summary: string;
  contract_is_pro: boolean;
  contract_tags: FrontendTag[];
  supplierDisplay?: SupplierDisplay;
  supplierHasProducts?: boolean;
  proQualifications?: ProQualifications;
  supplier: BaseSupplier;
  supplierAgreement?: SupplierAgreementData;
  supplierCompliance?: SupplierCompliance;
  supplier_catalog_url: Maybe<string>;
  supplier_contact: { full_name?: string; title?: string };
  supplier_phone_contacts: SupplierContact[];
  supplier_service_area_data: SupplierServiceAreaData;
  contract_document_data?: ContractDocumentData;
  contract_edit_request_data?: ContractEditRequestData;
  renewals: Renewal[];
  effective_date: Maybe<string | Date>;
  is_autorenewing: Maybe<boolean>;
  contract_scope_type: Maybe<ContractScopeTypeEnum>;
  supplier_keywords: string[];
}

interface ContractProps extends AuthPageProps {
  page_id?: string;
  solicitation: ISolicitation;
  selected_contract: string;
  rank: string;
}

export default function Contract({
  solicitation,
  selected_contract,
  rank,
  is_authenticated,
  profile_type,
  active_agreements,
}: ContractProps) {
  return (
    <Page
      pageId={selected_contract}
      pageType={PageType.CONTRACT}
      searchSource="contract-solicitation-header-search-bar"
      isAuthenticated={is_authenticated}
      profileType={profile_type}
      activeAgreements={active_agreements}
    >
      <SolicitationPageContainer
        solicitation={solicitation}
        contractId={selected_contract}
        rank={rank}
      />
    </Page>
  );
}

const container = getDOMAnchorById("contract-detail-page") as HTMLElement;
if (_get(container, "dataset.solicitation")) {
  const solicitation = JSON.parse(container.dataset.solicitation || "");
  const selected_contract = container.dataset.selectedContract || "";
  const rank = container.dataset.rank || "";
  const root = createRoot(container);
  root.render(<Contract {...{ solicitation, rank, selected_contract }} />);
}
