/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `PRICING_DOC` - Pricing Doc
 * * `NOT_AVAILABLE_LOCATION` - Not Available Location
 * * `UNSUPPORTED_BY_SUPPLIER` - Unsupported By Supplier
 * * `UNSUPPORTED_BY_BLA` - Unsupported By Bla
 * * `UNSUPPORTED_BY_SOURCE` - Unsupported By Source
 * * `VERIFIED_CONTACT` - Verified Contact
 * * `LOCAL_SUPPLIER` - Local Supplier
 * * `PRO_SUPPLIER` - Pro Supplier
 * * `YEARS_IN_BUSINESS` - Years In Business
 * * `MAX_DELIVERY_TIME` - Max Delivery Time
 * * `SERVICE_PROVIDED` - Service Provided
 * * `MADE_IN_USA` - Made In Usa
 * * `BEST_PRICE_GUARANTEE` - Best Price Guarantee
 * * `FREE_CONSULTATION` - Free Consultation
 * * `FAMILY_OWNED` - Family Owned
 * * `GOVERNMENT_DISCOUNT` - Government Discount
 * * `RANKED_FIRST_ON_CONTRACT` - Ranked First On Contract
 */
export enum VariantEnum {
    PRICING_DOC = 'PRICING_DOC',
    NOT_AVAILABLE_LOCATION = 'NOT_AVAILABLE_LOCATION',
    UNSUPPORTED_BY_SUPPLIER = 'UNSUPPORTED_BY_SUPPLIER',
    UNSUPPORTED_BY_BLA = 'UNSUPPORTED_BY_BLA',
    UNSUPPORTED_BY_SOURCE = 'UNSUPPORTED_BY_SOURCE',
    VERIFIED_CONTACT = 'VERIFIED_CONTACT',
    LOCAL_SUPPLIER = 'LOCAL_SUPPLIER',
    PRO_SUPPLIER = 'PRO_SUPPLIER',
    YEARS_IN_BUSINESS = 'YEARS_IN_BUSINESS',
    MAX_DELIVERY_TIME = 'MAX_DELIVERY_TIME',
    SERVICE_PROVIDED = 'SERVICE_PROVIDED',
    MADE_IN_USA = 'MADE_IN_USA',
    BEST_PRICE_GUARANTEE = 'BEST_PRICE_GUARANTEE',
    FREE_CONSULTATION = 'FREE_CONSULTATION',
    FAMILY_OWNED = 'FAMILY_OWNED',
    GOVERNMENT_DISCOUNT = 'GOVERNMENT_DISCOUNT',
    RANKED_FIRST_ON_CONTRACT = 'RANKED_FIRST_ON_CONTRACT',
}
