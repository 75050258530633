import CheckRoundedIcon from "@mui/icons-material/CheckRounded";

import SupplierSearchInput, {
  type SupplierSearchResult,
} from "../../modals/SignupSteps/SupplierSearchInput";
import { Typography } from "../index";

import type { LabeledInputFieldProps } from "./LabeledInputField";

export default function SupplierInputField({
  field,
  form: { setFieldValue, errors, touched },
  placeholder,
  editable,
  dataTestId,
  size,
}: LabeledInputFieldProps<SupplierSearchResult>) {
  if (!editable) {
    return (
      <div className="flex flex-row gap-3 items-center">
        <Typography color="accent.green">{field.value.displayName}</Typography>
        <Typography
          component={CheckRoundedIcon}
          variant="headline"
          size="lg"
          color="success.default.primary.enabled"
          className=""
        />
      </div>
    );
  }

  return (
    <SupplierSearchInput
      value={field.value}
      onChange={(value, supplier) => {
        setFieldValue(field.name, supplier || { displayName: value });
      }}
      modalSource="supplier-signup-modal"
      placeholder={placeholder}
      dataTestId={dataTestId}
      errorMessage={
        touched[field.name] && errors[field.name]
          ? "Business name is required"
          : ""
      }
      size={size}
    />
  );
}
