import clsx from "clsx";

import { Typography } from "../../../library";
import type { OCRFile } from "../../../shared/types";
import { borderColorClass } from "../../../utils/colors";
import { FileItem, type HandleClickBookmarkOptions } from "./FileItem";
import { HiddenFileItem } from "./HiddenFileItem";

interface FileGroupProps {
  analyticsClassName?: string;
  contractNumber?: string;
  currentFileIndex: number;
  files: OCRFile[];
  header: string;
  onClick: (file: OCRFile) => void;
  onDownload: (file: OCRFile) => void;
  handleClickBookmark: ({ file, bookmark }: HandleClickBookmarkOptions) => void;
  sectionFiles: OCRFile[];
  supplierName: string;
}

export default function FileGroup({
  analyticsClassName,
  contractNumber,
  currentFileIndex,
  files,
  handleClickBookmark,
  header,
  onClick,
  onDownload,
  sectionFiles,
  supplierName,
}: FileGroupProps) {
  if (!sectionFiles.length) return null;

  return (
    <div className={clsx("grid gap-1", analyticsClassName)}>
      <Typography variant="headline" size="xs" color="neutral.bolder.enabled">
        {header}
      </Typography>
      <hr className={borderColorClass.neutral.subtle.enabled} />
      {sectionFiles.map((file: OCRFile) =>
        file.buyer_verification_gated ? (
          <HiddenFileItem
            key={file.id}
            file={file}
            contractNumber={contractNumber}
            supplierName={supplierName}
            className="justify-between"
          />
        ) : (
          <FileItem
            key={file.id}
            file={file}
            contractNumber={contractNumber}
            selected={file === files[currentFileIndex]}
            handleClickBookmark={handleClickBookmark}
            onDownload={onDownload}
            onClick={onClick}
            className="justify-between"
          />
        )
      )}
    </div>
  );
}
