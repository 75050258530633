import { useEffect, useRef } from "react";
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import { ItemTypeEnum } from "../../generated";
import useLoginWall, {
  WHITELISTED_SEARCH_SOURCE,
} from "../../hooks/useLoginWall";
import useShowProQualificationEffect from "../../hooks/useShowProQualificationEffect";
import { useTrackContractPageView } from "../../hooks/useTrackContractPageView";
import { CardContainer, CardContainerVariant } from "../../library";
import type { ISolicitation } from "../../pages/Contract";
import { savedProjectState } from "../../recoil/contract";
import useInitializeProductServices from "../../recoil/products";
import { initializeContractScopeCallback } from "../../recoil/scope";
import { widgetSearchSourceState } from "../../recoil/search";
import {
  isAuthenticatedState,
  profileTypeState,
  supplierState,
  userZipState,
} from "../../recoil/user";
import AnchorRefLinks, {
  type AnchorRefLink,
} from "../../shared/AnchorRefLinks";
import ProductList from "../../shared/ProductList";
import { borderColorClass } from "../../utils/colors";
import {
  LoginWallTriggers,
  ProfileType,
  pageNavigationSourceTypes,
} from "../../utils/enums";
import { getContractPath } from "../../utils/format";
import { trackAnchorLinkClick } from "../../utils/tracking";
import { AwardedSuppliers } from "./AwardedSuppliers";
import ContractFiles from "./ContractFiles";
import ContractRecommendations from "./ContractRecommendations";
import ContractSummary from "./ContractSummary";
import { ResellerSuppliers } from "./ResellerSuppliers";
import ScopeSummary from "./ScopeSummary";
import SolicitationPageCTA from "./SolicitationPageCTA";
import SolicitationPageHeader from "./SolicitationPageHeader";
import SupplierSummary from "./SupplierSummary";
import UnusableContractBanner from "./UnusableContractBanner";
import { scrollToSection } from "./utils";

export const BEST_MATCH_THRESHOLD = 70;

interface SolicitationPageProps {
  solicitationId?: string;
  pageType: Maybe<string>;
  solicitation: ISolicitation;
  rank?: string;
  contractId: string;
  query: string;
  analytics?: object;
  sourceKey?: string;
  savedProjectId?: string;
}

function SolicitationPage({
  solicitationId,
  pageType,
  solicitation: {
    cooperative_affiliation,
    buyer_lead_agency,
    title,
    contract_details,
    cooperative_affiliation_membership_url,
    cooperative_usage_summary,
    cooperative_usage_url,
    num_reseller_contracts,
    reseller_contracts,
    num_other_contracts,
    other_contracts,
  },
  contractId,
  savedProjectId,
  query,
  sourceKey = "",
}: SolicitationPageProps) {
  const widgetSearchSource = useRecoilValue(widgetSearchSourceState);
  const isAuthenticated = useRecoilValue(isAuthenticatedState);
  const initializeContractScope = useRecoilCallback(
    initializeContractScopeCallback,
    []
  );
  const requireLogin = useLoginWall();
  const scopeRef = useRef<HTMLDivElement>(null);
  const productsRef = useRef<HTMLDivElement>(null);
  const documentsRef = useRef<HTMLDivElement>(null);
  const awardedSuppliersRef = useRef<HTMLDivElement>(null);
  const supplierRef = useRef<HTMLDivElement>(null);
  const bottomViewerRef = useRef<HTMLDivElement>(null);
  const userZip = useRecoilValue(userZipState);
  const profileType = useRecoilValue(profileTypeState);
  const supplier = useRecoilValue(supplierState);
  const setSavedProject = useSetRecoilState(savedProjectState);

  const showProductList =
    !!contract_details.supplierHasProducts &&
    (profileType !== ProfileType.SUPPLIER ||
      contract_details.supplier.handle === supplier.handle);

  const showScopeSummary =
    contract_details.offerings.length > 0 ||
    contract_details.supplierHasProducts ||
    contract_details.supplier_keywords.length > 0;

  const showSupplierCTA =
    supplier?.id === contract_details.supplier.id ||
    profileType !== ProfileType.SUPPLIER;

  useShowProQualificationEffect({
    proQualifications: contract_details.proQualifications,
    supplierId: contract_details.supplier.id,
    supplierName: contract_details.supplier.displayName,
    solicitationId,
  });
  useInitializeProductServices({
    hasProducts: contract_details.supplierHasProducts || false,
    handle: contract_details.supplier.handle,
    name: contract_details.supplier.displayName,
    query: query || "",
  });

  useEffect(() => {
    if (!savedProjectId) return;
    setSavedProject({ id: savedProjectId, type: ItemTypeEnum.CONTRACT });
  });

  useEffect(() => {
    if (!query || query.length < 3) return;
    initializeContractScope(contract_details.contract_id, query);
  }, [initializeContractScope, contract_details.contract_id, query]);

  const numSuppliersOnSolicitation =
    num_reseller_contracts + num_other_contracts + 1; // Plus one for current supplier
  const { trackContractClick, requestID } = useTrackContractPageView({
    contractDetails: contract_details,
    contractId,
    solicitationId,
    title,
    otherContracts: other_contracts,
    query,
    numSuppliersOnSolicitation,
    buyerLeadAgency: buyer_lead_agency,
    cooperativeAffiliation: cooperative_affiliation,
    sourceKey,
    pageType,
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: Run only once when the page first renders to track viewing.
  useEffect(() => {
    if (
      !isAuthenticated &&
      widgetSearchSource &&
      WHITELISTED_SEARCH_SOURCE.includes(widgetSearchSource)
    ) {
      void requireLogin({
        triggerId: widgetSearchSource,
        triggerType: LoginWallTriggers.VIEW_SOLICITATION_PAGE,
      });
    }
  }, []);

  const anchorLinkTrackingParams = {
    contractId,
    supplierId: contract_details.supplier.id,
    supplierHandle: contract_details.supplier.handle,
    solicitationId,
  };

  const documentsTabName = `Documents ${
    contract_details.file_information.length
      ? ` (${contract_details.file_information.length})`
      : ""
  }`;

  function getContractPathWithAnalytics(id: string) {
    return getContractPath({
      solicitationId,
      contractId: id,
      query,
      queryZip: userZip,
      pageNavigationSource:
        pageNavigationSourceTypes.OTHER_SUPPLIERS_ON_CONTRACT,
      requestID,
    });
  }

  const links: AnchorRefLink[] = [
    {
      pageId: contractId,
      name: "Scope",
      ref: scopeRef,
      className: "analytics-contract-scope-summary-link",
      scrollOptions: { behavior: "smooth" },
      trackingParams: anchorLinkTrackingParams,
      requireLogin: false,
      hidden: !showScopeSummary,
    },
    {
      pageId: contractId,
      name: "Supplier",
      ref: supplierRef,
      className: "analytics-contract-supplier-link",
      scrollOptions: { behavior: "smooth" },
      trackingParams: anchorLinkTrackingParams,
      requireLogin: false,
    },
    {
      pageId: contractId,
      name: "Products",
      ref: productsRef,
      className: "analytics-contract-products-link",
      scrollOptions: { behavior: "smooth" },
      trackingParams: anchorLinkTrackingParams,
      requireLogin: false,
      hidden: !showProductList,
    },
    {
      pageId: contractId,
      name: documentsTabName,
      ref: documentsRef,
      className: "analytics-contract-documents-link",
      scrollOptions: { behavior: "smooth" },
      trackingParams: anchorLinkTrackingParams,
      requireLogin: true,
    },
  ];

  function handleFirstSupplierClick() {
    scrollToSection({
      sectionRef: supplierRef,
    });
    trackAnchorLinkClick({
      ...anchorLinkTrackingParams,
      linkName: "Contract summary main supplier",
      linkClassName: "analytics-contract-main-supplier-link",
    });
  }

  function handleOtherSuppliersClick() {
    scrollToSection({ sectionRef: awardedSuppliersRef });
    trackAnchorLinkClick({
      ...anchorLinkTrackingParams,
      linkName: "Contract summary other suppliers",
      linkClassName: "analytics-contract-other-suppliers-link",
    });
  }

  function handleDocumentsClick() {
    scrollToSection({ sectionRef: documentsRef });
    trackAnchorLinkClick({
      ...anchorLinkTrackingParams,
      linkName: "Contract summary documents",
      linkClassName: "analytics-contract-summary-documents-link",
    });
  }

  function handleProductListClick() {
    if (!showProductList) return;
    scrollToSection({ sectionRef: productsRef });
    trackAnchorLinkClick({
      ...anchorLinkTrackingParams,
      linkName: "Jump to product list",
      linkClassName: "analytics-contract-product-list-link",
    });
  }

  function scrollToViewer() {
    scrollToSection({
      sectionRef: bottomViewerRef,
      options: { behavior: "smooth", block: "end" },
    });
  }

  const numSuppliers = num_other_contracts + num_reseller_contracts;

  return (
    <div className="grid gap-y-8">
      <div className="grid grid-cols-4 md:grid-cols-12 gap-x-10 gap-y-8">
        <div className="grid col-start-1 col-span-full lg:col-end-9 gap-8">
          <CardContainer
            variant={CardContainerVariant.SECONDARY}
            className="p-6 rounded-6 h-fit"
          >
            <SolicitationPageHeader
              title={title}
              query={query}
              contractDetails={contract_details}
              solicitationId={solicitationId}
            >
              <ContractSummary
                title={title}
                query={query}
                contractDetails={contract_details}
                cooperativeAffiliation={cooperative_affiliation}
                cooperativeUsageSummary={cooperative_usage_summary}
                cooperativeUsageUrl={cooperative_usage_url}
                membershipUrl={cooperative_affiliation_membership_url}
                buyerLeadAgency={buyer_lead_agency}
                solicitationId={solicitationId}
                onFirstSupplierClick={handleFirstSupplierClick}
                onOtherSuppliersClick={handleOtherSuppliersClick}
                onClickDocuments={handleDocumentsClick}
                numSuppliers={numSuppliers}
              />
            </SolicitationPageHeader>
          </CardContainer>
          {showSupplierCTA && (
            <div className="lg:hidden max-w-fit">
              <SolicitationPageCTA
                contractDetails={contract_details}
                pageId={solicitationId}
                requestID={requestID}
              />
            </div>
          )}
          <AnchorRefLinks links={links} />
          <UnusableContractBanner
            supplierHandle={contract_details.supplier.handle}
            contractTags={contract_details.contract_tags}
          />
          {showScopeSummary && (
            <CardContainer
              ref={scopeRef}
              variant={CardContainerVariant.SECONDARY}
              className="p-6 rounded-6 h-fit scroll-m-[9.25rem]"
            >
              <ScopeSummary
                contractDetails={contract_details}
                scrollToViewer={scrollToViewer}
                handleProductListClick={handleProductListClick}
                handleDocumentsClick={handleDocumentsClick}
                showProductList={showProductList}
              />
            </CardContainer>
          )}
          <CardContainer
            variant={CardContainerVariant.SECONDARY}
            className="p-6 rounded-6 h-fit gap-6"
          >
            <SupplierSummary
              ref={supplierRef}
              contractId={contract_details.contract_id}
              supplier={contract_details.supplier}
              supplierCompliance={contract_details.supplierCompliance}
              supplierAgreement={contract_details.supplierAgreement}
            />
            {showProductList && (
              <>
                <hr className={borderColorClass.neutral.subtle.enabled} />
                <ProductList
                  ref={productsRef}
                  supplierHandle={contract_details.supplier.handle}
                  supplierName={contract_details.supplier.displayName}
                  supplierId={contract_details.supplier.id}
                  contractId={contract_details.contract_id}
                  contractScope={contract_details.contract_scope_type}
                />
              </>
            )}
          </CardContainer>
        </div>
        {showSupplierCTA && (
          <div className="col-span-4 xl:col-start-9 lg:grid hidden h-fit sticky top-30 z-1">
            <SolicitationPageCTA
              contractDetails={contract_details}
              pageId={solicitationId}
              requestID={requestID}
            />
          </div>
        )}
      </div>
      <CardContainer
        variant={CardContainerVariant.SECONDARY}
        className="p-6 rounded-6 h-fit scroll-m-[5.25rem]"
        ref={documentsRef}
      >
        <ContractFiles
          contractId={contractId}
          solicitationId={solicitationId as string}
          title={title}
          contractDetails={contract_details}
          buyerLeadAgency={buyer_lead_agency}
          cooperativeAffiliation={cooperative_affiliation}
          query={query}
          sourceKey={sourceKey}
          requestID={requestID}
          bottomViewerRef={bottomViewerRef}
        />
      </CardContainer>
      {!!num_other_contracts && (
        <CardContainer
          variant={CardContainerVariant.SECONDARY}
          className="p-6 rounded-6 h-fit scroll-m-[9.25rem]"
          ref={awardedSuppliersRef}
        >
          <AwardedSuppliers
            solicitationId={solicitationId}
            contractId={contractId}
            getContractPath={(id) => getContractPathWithAnalytics(id)}
            track={trackContractClick}
            totalNumSuppliers={num_other_contracts}
            initialContracts={other_contracts}
            query={query}
          />
        </CardContainer>
      )}
      {!!num_reseller_contracts && (
        <CardContainer
          variant={CardContainerVariant.SECONDARY}
          className="p-6 rounded-6 h-fit scroll-m-[9.25rem]"
        >
          <ResellerSuppliers
            totalNumContracts={num_reseller_contracts}
            solicitationId={solicitationId}
            contractId={contractId}
            getContractPath={(id) => getContractPathWithAnalytics(id)}
            track={trackContractClick}
            initialContracts={reseller_contracts}
          />
        </CardContainer>
      )}
      <ContractRecommendations
        contractId={contractId}
        contractDetails={contract_details}
        query={query}
        title={title}
        solicitationId={solicitationId}
        numSuppliersOnSolicitation={numSuppliers + 1}
        buyerLeadAgency={buyer_lead_agency}
        cooperativeAffiliation={cooperative_affiliation}
        sourceKey={sourceKey}
        trackContractClickWithinSolicitation={trackContractClick}
        pageType={pageType}
      />
    </div>
  );
}

export default SolicitationPage;
