import { atom, useSetAtom } from "jotai";
import { atom as recoilAtom } from "recoil";

import type { VisibilityPriority } from "../library/FeatureHighlightTooltip";
import type {
  ClickAnimatedPopupProps,
  TimedAnimatedPopupProps,
} from "../popups/AnimatedPopup";
import { NO_MODAL } from "../utils";
import type { PageType, PopupType } from "../utils/enums";

export const modalState = recoilAtom({
  key: "modalState",
  default: NO_MODAL,
});

export const pageTypeState = atom<Maybe<PageType>>(null);

export const pageIdState = atom<Maybe<string>>(null);

type CombinedPopupType = TimedAnimatedPopupProps & ClickAnimatedPopupProps;

type PopupStateProps = CombinedPopupType & {
  analyticsClassName?: string;
  name?: PopupType;
  type?: string;
  durationSeconds?: number;
};
export const popupState = atom<PopupStateProps | null>(null);

export const activeTooltipsState = atom<(keyof typeof VisibilityPriority)[]>(
  []
);

/**
 * Keep track of multiple active tooltips in order to control
 * which one is shown at a time.
 * See `assets/js/library/FeatureHighlightTooltip/index.tsx`
 */
export function useAddActiveTooltip() {
  const setTooltips = useSetAtom(activeTooltipsState);

  return (tooltipKey: keyof typeof VisibilityPriority) => {
    setTooltips((prev) => {
      if (prev.includes(tooltipKey)) return prev;
      return [...prev, tooltipKey];
    });
  };
}

export function useRemoveActiveTooltip() {
  const setTooltips = useSetAtom(activeTooltipsState);

  return (tooltipKey: keyof typeof VisibilityPriority) => {
    setTooltips((prev) => prev.filter((key) => key !== tooltipKey));
  };
}
