import useIsDebug from "../../hooks/useIsDebug";
import type { ContractSolicitationDetails } from "../../pages/Contract";
import { getTags } from "../../shared/ContractBase";
import SolicitationMetadata from "./SolicitationMetadata";

interface ContractSummaryProps {
  title: string;
  query: string;
  cooperativeAffiliation?: string;
  cooperativeUsageSummary?: string;
  cooperativeUsageUrl?: string;
  membershipUrl?: string;
  buyerLeadAgency: Maybe<string>;
  numSuppliers: number;
  onFirstSupplierClick: () => void;
  onOtherSuppliersClick?: () => void;
  solicitationId: Maybe<string>;
  contractDetails: ContractSolicitationDetails;
  onClickDocuments: () => void;
}

export default function ContractSummary({
  title,
  query,
  contractDetails,
  buyerLeadAgency,
  cooperativeAffiliation,
  cooperativeUsageSummary,
  cooperativeUsageUrl,
  membershipUrl,
  numSuppliers,
  solicitationId,
  onFirstSupplierClick,
  onOtherSuppliersClick,
  onClickDocuments,
}: ContractSummaryProps) {
  return (
    <div className="grid gap-2">
      <SolicitationTags contractDetails={contractDetails} />
      <SolicitationMetadata
        title={title}
        query={query}
        contractDetails={contractDetails}
        cooperativeAffiliation={cooperativeAffiliation}
        cooperativeUsageSummary={cooperativeUsageSummary}
        cooperativeUsageUrl={cooperativeUsageUrl}
        membershipUrl={membershipUrl}
        buyerLeadAgency={buyerLeadAgency}
        numSuppliers={numSuppliers}
        solicitationId={solicitationId || ""}
        onOtherSuppliersClick={onOtherSuppliersClick}
        onFirstSupplierClick={onFirstSupplierClick}
        onClickDocuments={onClickDocuments}
      />
    </div>
  );
}

function SolicitationTags({
  contractDetails,
}: {
  contractDetails: ContractSolicitationDetails;
}) {
  const isDebug = useIsDebug();
  const { tags } = getTags({
    contractTagData: contractDetails.contract_tags,
    isCooperative: contractDetails.cooperative_language,
    expiration_date: contractDetails.expiration_date,
    excludeDocumentTags: true,
    excludeSupportTags: true,
    isDebug,
    transparent: true,
  });

  if (!tags.length) return null;

  return <div className="w-full flex flex-row gap-2">{tags}</div>;
}
