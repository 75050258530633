import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded";
import LocationCityOutlined from "@mui/icons-material/LocationCityOutlined";
import clsx from "clsx";
import pluralize from "pluralize";

import useLoginWall from "../../hooks/useLoginWall";
import { Badge, Button, ButtonThemes, Typography } from "../../library";
import { LoginWallTriggers } from "../../utils/enums";
import { formatEntityType } from "../../utils/format";
import { trackLandingPageViaBlaClick } from "../../utils/tracking";
import type { GovernmentAgency } from "../types";

interface BLACardTitleProps {
  agency: GovernmentAgency;
  onClick: () => void;
}

function BLACardTitle({ agency, onClick }: BLACardTitleProps) {
  if (agency.url) {
    return (
      <Badge
        reverse
        onClick={onClick}
        key={agency.name}
        className="analytics-landing-page-via-bla-name justify-between cursor-pointer text-cp-lapis-500 font-semibold"
        iconClass="analytics-landing-page-via-bla-icon"
        Icon={LaunchRoundedIcon}
      >
        {agency.name}
      </Badge>
    );
  }
  return (
    <Typography
      variant="headline"
      size="xs"
      color="brand.default.primary.enabled"
      emphasis
    >
      {agency.name}
    </Typography>
  );
}

interface BLACardProps {
  agency: GovernmentAgency;
  showType?: boolean;
  showButton?: boolean;
  className?: string;
  requestID?: string;
  trackSerpClick?: () => void;
}

export function BLACard({
  agency,
  showType = true,
  showButton = true,
  className,
  requestID,
  trackSerpClick,
}: BLACardProps) {
  const requireLogin = useLoginWall();

  const handleClick = (source: string) => {
    trackSerpClick?.();
    void requireLogin({
      triggerId: agency.name,
      onComplete: () => {
        window.open(agency.url);
      },
      triggerType: LoginWallTriggers.CONTRACT_SEARCH_BLA_CLICK,
    });
    trackLandingPageViaBlaClick({
      requestID,
      buyerLeadAgencyId: agency.id,
      buyerLeadAgencyName: agency.name,
      source,
    });
  };

  const handleClickButton = () => {
    handleClick("button");
  };

  const handleClickName = () => {
    handleClick("name");
  };

  if (!agency.name) {
    return null;
  }

  return (
    <div
      className={clsx(
        className,
        "analytics-serp-bla-card bg-white rounded-lg p-5 gap-x-1 bg-cp-violet-100 border-cp-neutral-40 border border-solid"
      )}
    >
      <BLACardTitle agency={agency} onClick={handleClickName} />
      <div className="flex items-end justify-between">
        <div className="flex flex-col mt-4">
          {showType && (
            <Badge
              Icon={LocationCityOutlined}
              iconClass="text-cp-leaf-300"
              className="text-cp-black-100"
              label={
                <div className="mb-2">
                  {formatEntityType(agency.entityType)}
                </div>
              }
            />
          )}
          {agency.numContracts > 0 && (
            <Badge
              Icon={DescriptionOutlinedIcon}
              iconClass="text-cp-leaf-300"
              className="text-cp-black-100"
              label={
                <div>
                  {agency.numContracts.toLocaleString()}
                  {" active shareable "}
                  {pluralize("contract", agency.numContracts)}
                </div>
              }
            />
          )}
        </div>
        {showButton && (
          <Button
            theme={ButtonThemes.PRIMARY_DARK}
            onClick={handleClickButton}
            className="analytics-landing-page-via-bla-button"
            size={Button.sizes.SMALL}
          >
            View contracts
          </Button>
        )}
      </div>
    </div>
  );
}
