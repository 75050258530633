import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import DescriptionOutlined from "@mui/icons-material/DescriptionOutlined";
import pluralize from "pluralize";
import { type ReactElement, useRef } from "react";

import type { SupplierDiversityCert } from "../../../generated";
import { Badge, Bullets, Link, Typography } from "../../../library";
import { supplierHasFeature } from "../../../utils/featureManagement";
import { trackViewSupplierAdditionalContracts } from "../../../utils/tracking";
import DiversityCertifications from "../../SolicitationPage/DiversityCertifications";
import SupplierAboutSummary from "./SupplierAboutSummary";

interface AboutSupplierProps {
  id: number;
  aliases?: string[];
  about: Maybe<string>;
  offeringsList?: string[];
  website?: Maybe<string>;
  addressCity: Maybe<string>;
  addressStateCode: Maybe<string>;
  handle?: Maybe<string>;
  activeAgreements: string[];
  activeContractCount?: Maybe<number>;
  diversityCertifications?: SupplierDiversityCert[];
  header?: ReactElement;
  catalog?: Maybe<string>;
  contractId?: Maybe<string>;
  publicEntitiesServed: Maybe<{ name: string }[]>;
  className?: string;
  showBottomDivider?: boolean;
}

export default function AboutSupplier({
  id,
  aliases,
  about,
  offeringsList,
  contractId,
  website,
  addressCity,
  addressStateCode,
  activeContractCount,
  activeAgreements,
  handle,
  diversityCertifications,
  header,
  catalog,
  publicEntitiesServed,
  showBottomDivider,
  className,
}: AboutSupplierProps) {
  const contractsSectionRef = useRef<null | HTMLDivElement>(null);
  const onViewAdditionalContractsClick = () => {
    if (contractsSectionRef?.current) {
      contractsSectionRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
    trackViewSupplierAdditionalContracts({
      supplierId: id,
      supplierHandle: handle,
    });
  };

  if (
    !about &&
    !aliases?.length &&
    !website &&
    !addressCity &&
    !addressStateCode &&
    !offeringsList?.length &&
    !diversityCertifications?.length &&
    !catalog
  ) {
    return null;
  }

  return (
    <div className={className}>
      {header}
      <div className="grid gap-4">
        {!header && (
          <Typography
            variant="headline"
            size="md"
            emphasis
            color="brand.default.secondary.enabled"
          >
            About
          </Typography>
        )}
        {!!activeContractCount && (
          <Badge
            Icon={DescriptionOutlined}
            as={Link}
            onClick={onViewAdditionalContractsClick}
            linkProps={{ underline: false }}
          >
            {activeContractCount} active shareable{" "}
            {pluralize("contract", activeContractCount)}
          </Badge>
        )}
        <SupplierAboutSummary
          id={id}
          about={about}
          aliases={aliases}
          catalog={catalog}
          website={website}
          handle={handle}
          addressCity={addressCity}
          addressStateCode={addressStateCode}
          activeAgreements={activeAgreements}
        />
      </div>
      {!!offeringsList?.length && (
        <div className="flex flex-col gap-4">
          <Typography
            variant="headline"
            size="xs"
            emphasis
            color="brand.default.secondary.enabled"
          >
            Confirmed in contract scope
          </Typography>
          <Bullets
            itemsList={offeringsList}
            numItemsWhenMinimized={6}
            expandCtaOptions={{
              className: "analytics-view-more-offerings",
            }}
            bulletIcon={
              supplierHasFeature(activeAgreements, "confirmedOfferings") ? (
                <Badge Icon={CheckRoundedIcon} iconClass="text-cp-leaf-500" />
              ) : undefined
            }
          />
        </div>
      )}
      {!!publicEntitiesServed?.length &&
        supplierHasFeature(activeAgreements, "publicEntitiesServed") && (
          <div className="grid gap-4">
            <Typography
              variant="headline"
              size="xs"
              emphasis
              color="brand.default.secondary.enabled"
            >
              Public entities served
            </Typography>
            <Bullets
              itemsList={publicEntitiesServed.map(({ name }) => name)}
              numItemsWhenMinimized={6}
              expandCtaOptions={{
                className: "analytics-view-more-public-entities",
              }}
              capitalize={false}
            />
          </div>
        )}
      {diversityCertifications && (
        <DiversityCertifications
          diversityCertifications={diversityCertifications}
          supplierId={id}
          handle={handle}
          contractId={contractId}
        />
      )}
      <div ref={contractsSectionRef} />
      {showBottomDivider && (
        <div className="pt-2 border-solid border-cp-neutral-20 border-t" />
      )}
    </div>
  );
}
