import { useRecoilState, useRecoilValue } from "recoil";
import {
  userState as recoilUserState,
  userStateState,
  userZipState,
} from "../../recoil/user";

import type { ContractSolicitationDetails } from "../../pages/Contract";
import { getParam } from "../../utils";
import { supplierHasFeature } from "../../utils/featureManagement";
import { trackContactSupplierFromSolicitation } from "../../utils/tracking";

import SupplierCTAContainer from "../supplier/SupplierCTAContainer";

import { patchUserState } from "../../utils/api";
import UploadDocsCTAContainer from "./UploadDocsCTAContainer";

interface SolicitationPageCTAProps {
  contractDetails: ContractSolicitationDetails;
  pageId: Maybe<string>;
  requestID: Maybe<string>;
}

export default function SolicitationPageCTA({
  contractDetails,
  pageId,
  requestID,
}: SolicitationPageCTAProps) {
  const userZip = useRecoilValue(userZipState);
  const { supplier } = useRecoilValue(recoilUserState);
  const [userState, setUserState] = useRecoilState(userStateState);
  const query = getParam("query");
  const hasFiles = contractDetails.file_information.length !== 0;

  if (
    supplierHasFeature(supplier?.activeAgreements, "contractManagement") &&
    contractDetails.contract_document_data &&
    contractDetails.contract_edit_request_data
  ) {
    const missingDocs = [];
    let title = "Help buyers evaluate your contract";
    let subtitle =
      "Upload additional documents to help verified buyers assess if your contract fits their needs.";
    if (contractDetails.contract_edit_request_data.needsExpirationReview) {
      missingDocs.push("Renewal document (Required)");
      title = "Has your contract been renewed?";
      subtitle = "If so, add documents to let buyers know!";
    }
    if (!contractDetails.contract_document_data.hasContractDoc) {
      missingDocs.push("Signed contract document (Required)");
    }
    if (!contractDetails.contract_document_data.hasRfpBidDoc) {
      missingDocs.push("RFP document");
    }
    if (!contractDetails.contract_document_data.hasPricingDoc) {
      missingDocs.push("Pricing document");
    }

    return (
      <div className="sticky top-24">
        <UploadDocsCTAContainer
          title={title}
          subtitle={
            subtitle + (missingDocs.length > 0 ? " Add the following: " : "")
          }
          missingDocs={missingDocs}
          supplierId={contractDetails.supplier.id}
          contractDetails={{
            docid: contractDetails.contract_id,
            title: contractDetails.supplier.displayName,
            contractNumber: contractDetails.contract_number,
            expirationDate: contractDetails.expiration_date as string,
          }}
        />
      </div>
    );
  }
  return (
    <SupplierCTAContainer
      id={contractDetails.supplier.id}
      name={contractDetails.supplier.displayName}
      handle={contractDetails.supplier.handle}
      activeAgreements={
        contractDetails.supplierAgreement?.activeAgreements || []
      }
      solicitationId={pageId}
      contractId={contractDetails.contract_id}
      showNoDocuments={!hasFiles}
      trackClick={() => {
        trackContactSupplierFromSolicitation({
          contractId: contractDetails.contract_id,
          queryZip: userZip,
          requestID,
          searchQuery: query,
          solicitationId: pageId,
          supplierHandle: contractDetails.supplier.handle,
          supplierId: contractDetails.supplier.id,
        });
        if (!userState.hasContactedSupplier) {
          patchUserState({ hasContactedSupplier: true });
          setUserState((prev) => ({
            ...prev,
            hasContactedSupplier: true,
          }));
        }
      }}
      supplierContacts={contractDetails.supplier_phone_contacts}
      contractCatalogUrl={contractDetails.catalog_url}
    />
  );
}
