import { useState } from "react";

import CompleteBuyerAccountForm from "../../modals/SignupSteps/CompleteBuyerAccountForm";
import { userTypeTabData } from "../../modals/SignupSteps/constants";
import {
  type AccountModalKeys,
  type InferredBLA,
  WindowType,
} from "../../modals/SignupSteps/types";
import AccountPage from "../../pages/Account";
import { isFreshSignupAtom } from "../../recoil/history";
import { getParams } from "../../utils";
import { accountModals, modalTriggerURLParams } from "../../utils/enums";
import { trackSignupLoginExit } from "../../utils/tracking";

import { useSetAtom } from "jotai";
import useShowSignupSurvey from "../../hooks/useShowSignupSurvey";
import { educationalData } from "./constants";
import {
  goToHomePage,
  goToSignupPage,
  goToSignupWelcomePage,
  redirectOnComplete,
} from "./helpers";

interface CompleteBuyerSignupPageProps {
  email: string;
  inferredBLA: InferredBLA;
}
export default function CompleteBuyerSignupPage({
  email,
  inferredBLA,
}: CompleteBuyerSignupPageProps) {
  const urlParams = getParams();
  const invite = urlParams[modalTriggerURLParams.INVITE];
  const setIsFreshSignup = useSetAtom(isFreshSignupAtom);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const showSignupSurvey = useShowSignupSurvey();

  // If this is an explicitly provided invite, prefer that BLA.
  if (invite && urlParams.blaId && urlParams.blaName) {
    inferredBLA.id = urlParams.blaId as string;
    inferredBLA.display_name = urlParams.blaName as string;
  }
  const buyerType = educationalData.buyer.type;

  const title = showConfirmation
    ? "Are you a supplier?"
    : educationalData[buyerType].title;
  const subtitle = showConfirmation
    ? "It seems like you may be a supplier! With a supplier account, you can provide key information about your business to help us match your contracts with relevant searches."
    : undefined;
  const buyerEducationalInfo = showConfirmation
    ? []
    : educationalData[buyerType].educationalInfo;
  const goBack = () => goToSignupPage();

  // Once user has completed the signup step, their account is created.
  // Hence, this modal should be blocking and user should be forced to trigger a refresh via "Skip"
  function onComplete(redirectUrl: string) {
    showSignupSurvey({
      onComplete: () => {
        if (!redirectUrl.endsWith("/welcome")) redirectOnComplete(redirectUrl);
        else goToSignupWelcomePage(setIsFreshSignup);
      },
    });
  }

  function closePage() {
    trackSignupLoginExit({
      accountType: userTypeTabData.buyer.type,
      emailEntered: email as string,
      loginStage: accountModals.SIGNUP_STEPS_SIGNUP as AccountModalKeys,
      loginExperience: WindowType.Page,
    });
    goToHomePage();
  }

  return (
    <AccountPage
      title={title}
      info={buyerEducationalInfo}
      ctaText={subtitle}
      goBack={goBack}
      closePage={closePage}
    >
      <CompleteBuyerAccountForm
        hideModal={() => {}}
        onComplete={onComplete}
        inferredBLA={inferredBLA}
        onChangeConfirmation={setShowConfirmation}
        parentWindow={WindowType.Page}
      />
    </AccountPage>
  );
}
