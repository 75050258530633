import _isEmpty from "lodash/isEmpty";
import { useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { useBindPageParamDict } from "../../hooks/useBindPageParam";
import useDefaultSearchPreferences from "../../hooks/useDefaultSearchPreferences";
import useSearchContractWithParams from "../../hooks/useSearchContractWithParams";
import useSearchIntentSurvey from "../../hooks/useSearchIntentSurvey";
import {
  approvedSourcesOnlyFilterState,
  contractSearchIsLoadingState,
  contractSearchParamsState,
  contractSearchResponseDataState,
  contractSourceFilterState,
  diversityCertificationsFilterState,
  searchResultTypeState,
  singleAwardOnlyFilterState,
  supplierSearchIsLoadingState,
  supplierSearchResponseDataState,
} from "../../recoil/search";
import {
  buyerProfileState,
  isAuthenticatedState,
  userStateState,
  userZipState,
} from "../../recoil/user";
import SearchPage from "../../shared/SearchPage/SearchPage";
import { SearchIntent } from "../../utils/enums";
import GovAgencyMatch from "./GovAgencyMatch";
import type { ContractSearchParams } from "./types";
import { formatSearchPageParams } from "./utils";

export default function ContractSearch() {
  const userState = useRecoilValue(userStateState);
  const { governmentAgency } = useRecoilValue(buyerProfileState);
  const defaultSearchPreferences = useDefaultSearchPreferences();
  const isAuthenticated = useRecoilValue(isAuthenticatedState);
  const setDiversityCertificationsFilter = useSetRecoilState(
    diversityCertificationsFilterState
  );
  const setSingleAwardOnlyState = useSetRecoilState(singleAwardOnlyFilterState);
  const setApprovedSourcesOnlyFilter = useSetRecoilState(
    approvedSourcesOnlyFilterState
  );
  const contractResponseData = useRecoilValue(contractSearchResponseDataState);
  const [searchParams, setSearchParams] = useRecoilState(
    contractSearchParamsState
  );
  const searchResultType = useRecoilValue(searchResultTypeState);

  const contractSearchIsLoading = useRecoilValue(contractSearchIsLoadingState);
  const supplierSearchIsLoading = useRecoilValue(supplierSearchIsLoadingState);
  const supplierResponseData = useRecoilValue(supplierSearchResponseDataState);
  const userZip = useRecoilValue(userZipState);

  const searchContractWithParams = useSearchContractWithParams();
  const searchIntentSurvey = useSearchIntentSurvey();

  // NOTE: LIQUID GOLD ANALYTICS & HEAP RELY ON THESE PARAM IN THE URL.
  // PLEASE COORDINATE IF THESE CHANGE.
  useBindPageParamDict(searchParams, setSearchParams);

  useEffect(() => {
    setDiversityCertificationsFilter(
      !!userState && !!userState.showDiversityCertifications
    );
    setSingleAwardOnlyState(!!userState && !!userState.showOnlySingleAward);
  }, [setSingleAwardOnlyState, setDiversityCertificationsFilter, userState]);

  useEffect(() => {
    setApprovedSourcesOnlyFilter(
      !!governmentAgency && !!governmentAgency?.showOnlyApprovedSources
    );
  }, [setApprovedSourcesOnlyFilter, governmentAgency]);

  const waitForSearch = useMemo(
    () => _isEmpty(searchParams) || (isAuthenticated && _isEmpty(userState)),
    [searchParams, isAuthenticated, userState]
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: Only rerun searches if 'wait for search' changes.
  useEffect(() => {
    if (
      contractSearchIsLoading ||
      contractResponseData ||
      supplierResponseData ||
      supplierSearchIsLoading
    )
      return;
    if (waitForSearch) return;
    // pass zip if available
    let update: Partial<ContractSearchParams> = userZip ? { zip: userZip } : {};
    update.collapseBySupplier =
      searchResultType === "supplier" &&
      searchParams?.intent !== SearchIntent.RESEARCHING;
    if (!searchParams.filters) {
      update = {
        ...update,
        ...defaultSearchPreferences,
      };
    }
    (async () => {
      const { filters } = await searchIntentSurvey();
      if (filters?.length) {
        if (update.filters) update.filters?.push(...filters);
        else update.filters = filters;
      }
      searchContractWithParams({ newParams: update });
    })();
  }, [waitForSearch]);

  const params = formatSearchPageParams(searchParams);

  const setContractSourceFilter = useSetRecoilState(contractSourceFilterState);
  const [isFirstSearch, setIsFirstSearch] = useState(true);

  // If the prioritized entity (i.e. the government affiliation) did not have any
  // associated contract search results, then manually add the entity to the list of
  // contract sources, otherwise there is no visual indication that we're filtering by it.
  // biome-ignore lint/correctness/useExhaustiveDependencies: No need to run this check when the setters change.
  useEffect(() => {
    const govAffiliation =
      contractResponseData?.prioritizedEntityMatchData?.agencyInfo.name;
    if (isFirstSearch && govAffiliation) {
      setContractSourceFilter([govAffiliation]);
      setIsFirstSearch(false);
    }
  }, [contractResponseData?.prioritizedEntityMatchData]);

  const nearbyAgencies = contractResponseData?.agencyData?.relatedAgencies;

  return (
    <div className="px-6 md:px-8">
      <div className="max-w-[75rem] m-auto">
        {contractResponseData && (
          <GovAgencyMatch
            agency={contractResponseData.agencyData?.agency}
            relatedAgencies={nearbyAgencies || []}
            requestID={contractResponseData.params?.requestId || ""}
          />
        )}
        <SearchPage params={params} />
      </div>
    </div>
  );
}
