import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { captureException } from "@sentry/browser";
import clsx from "clsx";
import { type ReactNode, type RefObject, useState } from "react";
import { useRecoilValue } from "recoil";

import { useAtom, useSetAtom } from "jotai";
import useLoginWall from "../../../hooks/useLoginWall";
import useRequestMissingDocuments from "../../../hooks/useRequestMissingDocuments";
import useShowModal from "../../../hooks/useShowModal";
import { Badge, Typography } from "../../../library";
import type { ShowBuyerOptInBeforeDownloadModal } from "../../../modals/BuyerOptInModals/BuyerOptinBeforeDownloadModal";
import type { ContractSolicitationDetails } from "../../../pages/Contract";
import { ErrorPopup } from "../../../popups/AnimatedPopup";
import { savedProjectState } from "../../../recoil/contract";
import {
  hasSeenProjectPromptAtom,
  optedInOutreachSuppliersAtom,
} from "../../../recoil/history";
import { popupState } from "../../../recoil/page";
import {
  isAuthenticatedState,
  profileTypeState,
  userStateState,
  userZipState,
} from "../../../recoil/user";
import {
  LoginWallTriggers,
  MODAL_SOURCE,
  PopupType,
  ProfileType,
  modals,
} from "../../../utils/enums";
import {
  trackContactBLAFromSolicitation,
  trackFileDownloaded,
  trackGTMEvent,
  trackSupplierOutreachOptInDownload,
  trackSupplierOutreachOptOutDownload,
  trackViewSourceClick,
} from "../../../utils/tracking";
import { downloadAllFiles } from "../utils";
import FilesSection from "./FilesSection";
import NoContractFilesBanner from "./NoContractFilesBanner";

interface ContractFilesProps {
  contractId: string;
  contractDetails: ContractSolicitationDetails;
  title: string;
  cooperativeAffiliation: string;
  buyerLeadAgency: string;
  query?: string;
  sourceKey: string;
  solicitationId: string;
  requestID?: string;
  bottomViewerRef: RefObject<HTMLDivElement>;
}

export default function ContractFiles({
  contractId,
  contractDetails,
  title,
  buyerLeadAgency,
  cooperativeAffiliation,
  query,
  solicitationId,
  requestID,
  bottomViewerRef,
}: ContractFilesProps) {
  const {
    contract_id,
    contract_number,
    file_information,
    source_url,
    supplier,
    supplier_contact,
  } = contractDetails;
  const [onRequestDocuments, isLoading] = useRequestMissingDocuments(
    contractId,
    solicitationId
  );
  const hasFiles = file_information?.length;
  const downloadableFiles =
    file_information?.filter((f) => !f.buyer_verification_gated) ?? [];
  const [downloadError, setDownloadError] = useState<ReactNode>(null);
  const isAuthenticated = useRecoilValue(isAuthenticatedState);
  const profileType = useRecoilValue(profileTypeState);
  const userZip = useRecoilValue(userZipState);
  const setPopupState = useSetAtom(popupState);
  const { supplierContactOptIn } = useRecoilValue(userStateState);
  const [optedInOutreachSuppliers, setOptedInOutreachSuppliers] = useAtom(
    optedInOutreachSuppliersAtom
  );
  const [hasSeenProjectPrompt, setHasSeenProjectPrompt] = useAtom(
    hasSeenProjectPromptAtom
  );
  const savedProject = useRecoilValue(savedProjectState);
  const requireLogin = useLoginWall();
  const showContactBlaModal = useShowModal(modals.CONTACT_BUYER_LEAD_AGENCY);
  const showPostDownloadModal = useShowModal(modals.POST_DOWNLOAD_MODAL);
  const showBuyerBeforeDownloadOptInModal: ShowBuyerOptInBeforeDownloadModal =
    useShowModal(modals.BUYER_OPT_IN_BEFORE_DOWNLOAD_MODAL);
  const blaRestrictsDocumentAccess = file_information?.some(
    ({ has_access }) => !has_access
  );
  const accessDenied = !isAuthenticated || blaRestrictsDocumentAccess;
  const restrictDocumentAccess = isAuthenticated && blaRestrictsDocumentAccess;

  function showMessageContractAdminModal() {
    showContactBlaModal({
      contractId: contractDetails.contract_id,
      searchQuery: query,
      queryZip: userZip,
      solicitationId,
      supplierId: contractDetails.supplier.id,
      supplierDisplayName: contractDetails.supplier.displayName,
      buyerLeadAgency,
      blaPOC: contractDetails.bla_poc,
      requestID,
    });
    trackContactBLAFromSolicitation({
      contractId: contractDetails.contract_id,
      searchQuery: query || "",
      queryZip: userZip,
      solicitationId: solicitationId,
      supplierId: contractDetails.supplier.id,
      buyerLeadAgency: buyerLeadAgency,
      blaPOC: contractDetails.bla_poc,
      requestID,
    });
  }

  const onClickMessageContractAdmin = () => {
    void requireLogin({
      triggerId: contractDetails.contract_id,
      triggerType: LoginWallTriggers.MESSAGE_SUPPLIER_FROM_CONTRACT,
      onComplete: () => showMessageContractAdminModal(),
    });
  };

  function showOutreachSuccessPopup() {
    setPopupState({
      analyticsClassName: "analytics-supplier-outreach-opt-in-download-success",
      name: PopupType.SUCCESS,
      children: (
        <Typography color="inverse">
          Supplier has been notified and will be in touch soon!
        </Typography>
      ),
      show: true,
    });
  }

  function showPostDownloadModalIfNotSeen() {
    if (hasSeenProjectPrompt || savedProject?.id) return;
    setHasSeenProjectPrompt(true);
    showPostDownloadModal({
      contractId,
    });
  }

  const onClickDownloadAll = async () => {
    try {
      if (
        supplierContactOptIn &&
        profileType === ProfileType.BUYER &&
        !optedInOutreachSuppliers.includes(supplier.id)
      ) {
        setOptedInOutreachSuppliers([...optedInOutreachSuppliers, supplier.id]);
        showBuyerBeforeDownloadOptInModal({
          supplier,
          contractId,
          contractTitle: title,
          solicitationId,
          query,
          supplierPOC: supplier_contact?.full_name || "",
          source: MODAL_SOURCE.BEFORE_DOWNLOAD,
          trackOptIn: trackSupplierOutreachOptInDownload,
          trackOptOut: trackSupplierOutreachOptOutDownload,
          onSkip: showPostDownloadModalIfNotSeen,
          onComplete: () => {
            showOutreachSuccessPopup();
            showPostDownloadModalIfNotSeen();
          },
          downloadFiles: async () =>
            await downloadAllFiles(
              downloadableFiles,
              `${supplier.displayName}_${title}`
            ),
          shouldHideModalAfterCta: hasSeenProjectPrompt,
          modalType: "BUYER_OPT_IN_BEFORE_DOWNLOAD_MODAL",
        });
      } else {
        await downloadAllFiles(
          downloadableFiles,
          `${supplier.displayName}_${title}`
        );
        showPostDownloadModalIfNotSeen();
      }
    } catch (err) {
      captureException(err);
      setDownloadError(err as string);
    }

    trackFileDownloaded({
      supplierId: supplier.id,
      supplierName: supplier.displayName,
      supplierHandle: supplier.handle,
      contractId,
      buyerLeadAgency,
      cooperativeAffiliation,
      query,
      queryZip: userZip,
      numDocuments: downloadableFiles.length,
      downloadSource: "downloadAllCTA",
      requestID,
    });
    trackGTMEvent("file-opened");
  };

  function viewSource() {
    trackViewSourceClick({
      contractId: contract_id,
      supplierId: supplier.id,
      supplierHandle: supplier.handle,
      sourceUrl: source_url,
      requestID,
    });
    window.open(source_url, "_blank");
  }

  if (!hasFiles || restrictDocumentAccess) {
    return (
      <NoContractFilesBanner
        viewSource={viewSource}
        onRequestDocuments={onRequestDocuments}
        isLoading={isLoading}
        cooperativeAffiliation={cooperativeAffiliation}
        buyerLeadAgency={buyerLeadAgency}
        sourceUrl={source_url}
        numDocuments={file_information?.length || 0}
        onClickMessageContractAdmin={onClickMessageContractAdmin}
      />
    );
  }

  return (
    <div className="grid gap-8">
      <ErrorPopup show={!!downloadError} onClose={() => setDownloadError(null)}>
        <Badge
          Icon={WarningAmberRoundedIcon}
          className="line-clamp-2 hover:line-clamp-none"
          iconClass="mr-4"
        >
          <span>{downloadError}</span>
        </Badge>
      </ErrorPopup>
      <div className="file-header relative">
        <div className={clsx({ "analytics-not-blocked": !accessDenied })}>
          <FilesSection
            files={file_information}
            contractNumber={contract_number}
            supplier={supplier}
            contractId={contractId}
            title={title}
            solicitationId={solicitationId}
            supplierPOC={contractDetails.supplier_contact?.full_name}
            buyerLeadAgency={buyerLeadAgency}
            cooperativeAffiliation={cooperativeAffiliation}
            query={query}
            blockFiles={accessDenied}
            setDownloadError={setDownloadError}
            showOutreachSuccessPopup={showOutreachSuccessPopup}
            requestID={requestID}
            bottomViewerRef={bottomViewerRef}
            onClickDownloadAll={onClickDownloadAll}
            onClickMessageContractAdmin={onClickMessageContractAdmin}
            hasDownloadableFiles={!!downloadableFiles.length}
          />
        </div>
      </div>
    </div>
  );
}
