import clsx from "clsx";
import { Fragment, useMemo, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useRecoilValue } from "recoil";

import { PermissionRoleEnum } from "../../generated";
import useSelectTabByHash from "../../hooks/useSelectTabByHash";
import { PageSection, Typography } from "../../library";
import {
  buyerProfileState,
  profileTypeState,
  supplierActiveAgreementsState,
  userInitializedState,
} from "../../recoil/user";
import { getParam } from "../../utils";
import { ProfileType } from "../../utils/enums";
import { supplierHasFeature } from "../../utils/featureManagement";

import _flatten from "lodash/flatten";
import AdminApprovedSources from "./ApprovedSources/AdminApprovedSources";
import NonAdminApprovedSources from "./ApprovedSources/NonAdminApprovedSources";
import BillingPreferences from "./BillingPreferences";
import MyInfo from "./MyInfo";
import Preferences from "./Preferences";
import TeamMembers from "./TeamManagement/TeamManagement";

export default function ProfilePage({
  hasRequestedAdmin,
  numAdmins,
}: {
  hasRequestedAdmin: boolean;
  numAdmins: number;
}) {
  const isInitialized = useRecoilValue(userInitializedState);
  const profileType = useRecoilValue(profileTypeState);
  const { governmentAgency } = useRecoilValue(buyerProfileState);
  const activeAgreements = useRecoilValue(supplierActiveAgreementsState);
  const [selectedTab, setSelectedTab] = useState(0);
  const { permissionRole } = useRecoilValue(buyerProfileState);
  const isAdmin = permissionRole === PermissionRoleEnum.ADMIN;

  const fieldToExpandOnOpen = getParam("open");
  const showBillingPreferences =
    profileType === ProfileType.SUPPLIER &&
    supplierHasFeature(activeAgreements, "exclusions");
  const showTeamsSection =
    governmentAgency?.id && profileType === ProfileType.BUYER;

  const sections = useMemo(() => {
    if (!isInitialized) return [];
    const accountSectionTabs = [{ hash: "myInfo", title: "My info" }];
    if (profileType === ProfileType.BUYER) {
      accountSectionTabs.push({ hash: "preferences", title: "Preferences" });
    } else if (showBillingPreferences) {
      accountSectionTabs.push({
        hash: "billing-preferences",
        title: "Billing preferences",
      });
    }

    const sections = [
      {
        name: "My account",
        tabs: accountSectionTabs,
      },
    ];

    if (showTeamsSection) {
      sections.push({
        name: "Team",
        tabs: [
          {
            hash: "approved-sources",
            title: "Contract sources",
          },
          {
            hash: "team-management",
            title: "Team members",
          },
        ],
      });
    }
    return sections;
  }, [isInitialized, showTeamsSection, profileType, showBillingPreferences]);

  const tabList = useMemo(() => {
    if (!isInitialized) return [];
    return _flatten(sections.map(({ tabs }) => tabs));
  }, [isInitialized, sections]);

  useSelectTabByHash(tabList, setSelectedTab);

  return (
    <PageSection className="analytics-user-profile-page pt-10 pb-36">
      <Typography variant="headline" size="lg" color="brand.bold" emphasis>
        Account preferences
      </Typography>
      <Tabs
        selectedTabClassName="bg-violet-200"
        className="flex flex-col lg:grid lg:grid-cols-12 gap-x-6 gap-y-16 py-10 bg-cp-white-100 max-w-screen-2xl"
        onSelect={(index) => {
          window.location.hash = `#${tabList[index].hash}`;
        }}
        selectedIndex={selectedTab}
      >
        <div className="max-w-fit lg:grid lg:col-span-3 lg:grid-cols-3 lg:gap-x-6">
          <TabList className="flex flex-col gap-2 lg:col-span-2">
            {sections.map(({ name, tabs }) => (
              <Fragment key={name}>
                <Typography
                  variant="display"
                  size="xs"
                  color="neutral.bold.enabled"
                  className="py-2 px-4 hidden sm:block"
                >
                  {name}
                </Typography>
                {tabs.map(({ hash, title }) => (
                  <Tab
                    key={hash}
                    className={clsx(
                      "rounded-xl px-4 py-2 cursor-pointer",
                      `analytics-select-${hash}-tab`,
                      {
                        "hover:bg-cp-violet-100":
                          selectedTab !==
                          tabList.findIndex((tab) => tab.hash === hash),
                      }
                    )}
                  >
                    <Typography emphasis>{title}</Typography>
                  </Tab>
                ))}
              </Fragment>
            ))}
          </TabList>
        </div>
        {isInitialized && (
          <div className="lg:col-span-9">
            <TabPanel className="relative">
              <MyInfo fieldToExpandOnOpen={fieldToExpandOnOpen} />
            </TabPanel>
            {profileType === ProfileType.BUYER && (
              <TabPanel className="relative">
                <Preferences />
              </TabPanel>
            )}
            {showBillingPreferences && (
              <TabPanel className="relative">
                <BillingPreferences />
              </TabPanel>
            )}
            {showTeamsSection && (
              <TabPanel className="relative">
                {isAdmin || numAdmins === 0 ? (
                  <AdminApprovedSources />
                ) : (
                  <NonAdminApprovedSources />
                )}
              </TabPanel>
            )}
            {showTeamsSection && governmentAgency?.id && (
              <TabPanel className="relative">
                <TeamMembers
                  // Our lint rule forbids non-null assertions, so even though
                  // `showTeamManagement = ... && !!buyerProfile.governmentAgency?.id`,
                  // assert it again to pass typechecking.
                  governmentAgencyId={governmentAgency.id}
                  hasRequestedAdmin={hasRequestedAdmin}
                />
              </TabPanel>
            )}
          </div>
        )}
      </Tabs>
    </PageSection>
  );
}
