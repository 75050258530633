import type { CallbackInterface } from "recoil";

import type { accountModals } from "../../utils/enums";

export interface InferredBLA {
  id: string;
  display_name: string;
  recommended_contract_types?: string[];
}

export interface InferredSupplier {
  id: string;
  display_name: string;
  handle: string;
}

export type AccountModalKeys = keyof typeof accountModals;

export enum WindowType {
  Modal = "modal",
  Page = "page",
}
export type AccountAccessType = "sign up" | "log in";

export type CallbackFn = (
  callbackInterface: CallbackInterface
) => () => Promise<void>;
