import type { FileSection } from "../components/SolicitationPage/types";
import { FileTypeEnum } from "../generated";
import type { OCRFile } from "../shared/types";

export function useGroupContractFiles(files: OCRFile[]) {
  const awardSummaryDocuments: FileSection = {
    header: "Award summary documents",
    className: "analytics-files-contract-docs",
    files: [],
  };
  const contractDocuments: FileSection = {
    header: "Contract documents",
    className: "analytics-files-contract-docs",
    files: [],
  };
  const amendmentDocuments: FileSection = {
    header: "Amendment documents",
    className: "analytics-files-amendment-docs",
    files: [],
  };
  const bidSolicitationDocuments: FileSection = {
    header: "Bid solicitation documents",
    className: "analytics-files-bid-solicitation-docs",
    files: [],
  };
  const supplierResponseDocuments: FileSection = {
    header: "Supplier response documents",
    className: "analytics-files-other-docs",
    files: [],
  };
  const bidTabulationDocuments: FileSection = {
    header: "Bid tabulation documents",
    className: "analytics-files-bid-tabulation-docs",
    files: [],
  };
  const pricingDocuments: FileSection = {
    header: "Pricing documents",
    className: "analytics-files-pricing-docs",
    files: [],
  };
  const insuranceDocuments: FileSection = {
    header: "Insurance documents",
    className: "analytics-files-other-docs",
    files: [],
  };
  const supplierDocuments: FileSection = {
    header: "Supplier documents",
    className: "analytics-files-other-docs",
    files: [],
  };
  const otherDocuments: FileSection = {
    header: "Other documents",
    className: "analytics-files-other-docs",
    files: [],
  };

  files.forEach((file: OCRFile) => {
    switch (file.type) {
      case FileTypeEnum.AWARD_SUMMARY:
        awardSummaryDocuments.files.push(file);
        break;
      case FileTypeEnum.CONTRACT:
        contractDocuments.files.push(file);
        break;
      case FileTypeEnum.AMENDMENT:
      case FileTypeEnum.RENEWAL:
        amendmentDocuments.files.push(file);
        break;
      case FileTypeEnum.BID_SOLICITATION:
        bidSolicitationDocuments.files.push(file);
        break;
      case FileTypeEnum.SUPPLIER_RESPONSE:
        supplierResponseDocuments.files.push(file);
        break;
      case FileTypeEnum.BID_TABULATION:
        bidTabulationDocuments.files.push(file);
        break;
      case FileTypeEnum.PRICING:
        pricingDocuments.files.push(file);
        break;
      case FileTypeEnum.INSURANCE:
        insuranceDocuments.files.push(file);
        break;
      case FileTypeEnum.SUPPLIER:
        supplierDocuments.files.push(file);
        break;
      default:
        otherDocuments.files.push(file);
    }
  });

  return [
    awardSummaryDocuments,
    contractDocuments,
    amendmentDocuments,
    bidSolicitationDocuments,
    supplierResponseDocuments,
    bidTabulationDocuments,
    pricingDocuments,
    insuranceDocuments,
    supplierDocuments,
    otherDocuments,
  ];
}
