import { PageSection } from "../../library";
import type { ISolicitation } from "../../pages/Contract";
import { pageTypeState } from "../../recoil/page";
import { getParams } from "../../utils";

import { useAtomValue } from "jotai";
import SolicitationPage from "./SolicitationPage";

interface SolicitationPageContainerProps {
  contractId: string;
  solicitationId?: string;
  solicitation: ISolicitation;
  rank?: string;
  savedProjectId?: string;
  sourceKey?: string;
}
function SolicitationPageContainer({
  contractId,
  solicitationId,
  solicitation,
  ...rest
}: SolicitationPageContainerProps) {
  const pageType = useAtomValue(pageTypeState);
  const params = getParams();
  const query = params.query;

  return (
    <PageSection className="bg-cp-neutral-0 mt-12 mb-24">
      <SolicitationPage
        {...rest}
        solicitation={solicitation}
        contractId={contractId}
        solicitationId={solicitationId}
        pageType={pageType}
        query={query as string}
      />
    </PageSection>
  );
}

export default SolicitationPageContainer;
