// Base generic card the app. Can be used generally for any card.

import clsx from "clsx";
import _get from "lodash/get";
import type { CSSProperties, MouseEventHandler, ReactNode } from "react";

import useLoginWall from "../../hooks/useLoginWall";
import {
  type BgColor,
  type BorderColor,
  bgColorClass,
  borderColorClass,
} from "../../utils/colors";
import { OUTLINE_FOCUS_CLASS } from "../../utils/constants";
import type { LoginWallTriggers } from "../../utils/enums";

export const CARD_WIDTH_STYLE = "max-w-[49.5rem] min-w-[21rem]";
const BASE_STYLE = "flex flex-col p-6 rounded-4 border border-solid gap-y-3";

export interface GenericCardProps {
  className?: string;
  tags?: ReactNode[];
  children?: ReactNode;
  onClick?: (e: unknown) => void;
  trackSerpClick?: () => void;
  loginWallTriggerId?: string;
  loginWallTrigger?: LoginWallTriggers;
  href?: string;
  bgColor?: BgColor;
  borderColor?: BorderColor;
  responsive?: boolean;
  // Use style prop only for styles that are only computed in runtime,
  // otherwise use tailwind classes.
  style?: CSSProperties;
  dataTestId?: string;
}

function GenericCard({
  className,
  children,
  onClick,
  trackSerpClick,
  loginWallTriggerId,
  loginWallTrigger,
  href,
  bgColor = "neutral.subtlest.enabled",
  borderColor = "neutral.subtle.enabled",
  responsive = false,
  style,
  dataTestId,
}: GenericCardProps) {
  const requireLogin = useLoginWall();

  // Wrap our onClick behavior in the login wall if we have the relevant props/state.
  const handleClick: MouseEventHandler = (e) => {
    trackSerpClick?.();
    if (!loginWallTrigger || !loginWallTriggerId) {
      onClick?.(e);
      return;
    }
    void requireLogin({
      triggerId: loginWallTriggerId,
      onComplete: onClick,
      triggerType: loginWallTrigger,
    });
  };

  return (
    <a
      data-testid={dataTestId}
      aria-label={dataTestId}
      href={href}
      onClick={handleClick}
      style={style}
      tabIndex={0}
      className={clsx(
        BASE_STYLE,
        OUTLINE_FOCUS_CLASS,
        "focus-visible:outline-cp-lapis-500",
        _get(bgColorClass, bgColor),
        _get(borderColorClass, borderColor),
        {
          [CARD_WIDTH_STYLE]: !responsive,
          "cursor-pointer hover:shadow-[0_0_2px_rgba(0,0,0,0.25),0_1px_10px_#E5EAF2]":
            href || onClick,
        },
        className
      )}
    >
      {children}
    </a>
  );
}

export default GenericCard;
