import _get from "lodash/get";
import pluralize from "pluralize";
import { createRoot } from "react-dom/client";
import SharedMessageSupplierPage, {
  type SharedMessageSupplierPageProps,
} from "../components/MessageSupplierPage/SharedMessageSupplierPage";
import { getPrefillText } from "../components/MessageSupplierPage/helpers";
import { MessageSupplierSource } from "../components/MessageSupplierPage/types";
import type { MessageSupplierData } from "../recoil/messageSupplier";
import { getDOMAnchorById, getParam, getRequestID } from "../utils";
import { PageType, pageNavigationSourceTypes } from "../utils/enums";
import {
  getContractPath,
  getMessageSupplierOnSolicitationUrl,
  getMessageSupplierUrl,
  getSupplierUrlPath,
  getWindowLocationHref,
} from "../utils/format";
import { trackContractNumberClick } from "../utils/tracking";

import type {
  BaseSupplier,
  SupplierAgreementData,
  SupplierCompliance,
  SupplierDisplay,
} from "../generated";
import type { ContractSolicitationDetails } from "./Contract";
import Page from "./Page/Page";

interface MessageSupplierSolicitation {
  cooperative_affiliation: string;
  buyer_lead_agency: string;
  buyer_lead_agency_url: string;
  title: string;
  contract_details: ContractSolicitationDetails;
}

interface MessageSupplierContact {
  fullName?: string;
  title?: Maybe<string>;
}

const container = getDOMAnchorById("message-supplier") as HTMLElement;
if (container) {
  let solicitation: Partial<MessageSupplierSolicitation> = {};
  let supplier: BaseSupplier = {
    id: 0,
    handle: "",
    logoUrl: "",
    displayName: "",
    about: "",
    addressCity: "",
    addressStateCode: null,
    offeringsList: [],
    displayAddress: "",
  };
  let contractIsPro = false;
  let supplierCompliance: SupplierCompliance = {
    tags: [],
    publicEntitiesServed: [],
    aliases: [],
    diversityCertifications: [],
  };
  let supplierDisplay: SupplierDisplay = {
    numActiveContracts: 0,
    numExpiredContracts: 0,
  };
  let supplierAgreement: SupplierAgreementData = {
    activeAgreements: [],
  };

  let contractId = "";
  let supplierContact: MessageSupplierContact = {};

  contractId = container.dataset.selectedContract || "";

  const id = container.dataset.pageid;
  const query = getParam("query");
  const requestID = getRequestID();
  const contractUrl = getContractPath({
    solicitationId: id,
    contractId,
    requestID,
  });

  let headerProps: SharedMessageSupplierPageProps["headerProps"];
  let messageSupplierSource: SharedMessageSupplierPageProps["messageSupplierSource"];
  let initialSuppliersToContact: SharedMessageSupplierPageProps["initialSuppliersToContact"];
  let supplierChipInputProps: SharedMessageSupplierPageProps["supplierChipInputProps"];
  let messageFormProps: SharedMessageSupplierPageProps["messageFormProps"];
  let onSuccessUrl: SharedMessageSupplierPageProps["onSuccessUrl"];
  let extraContractInfo: SharedMessageSupplierPageProps["extraContractInfo"];
  let extraSupplierInfo: SharedMessageSupplierPageProps["extraSupplierInfo"];
  let otherSupplierModalFromListProps: SharedMessageSupplierPageProps["otherSupplierModalFromListProps"];

  if (_get(container, "dataset.solicitation")) {
    solicitation = JSON.parse(container.dataset.solicitation || "");
    if (solicitation.contract_details) {
      supplier = solicitation.contract_details.supplier;
      supplierCompliance =
        solicitation.contract_details.supplierCompliance || supplierCompliance;
      supplierAgreement =
        solicitation.contract_details.supplierAgreement || supplierAgreement;
      supplierDisplay =
        solicitation.contract_details.supplierDisplay || supplierDisplay;
      contractIsPro = solicitation.contract_details.contract_is_pro;
      supplierContact = {
        fullName: solicitation.contract_details.supplier_contact.full_name,
        title: solicitation.contract_details.supplier_contact.title,
      };
    }

    const _trackContractNumberClick = () => {
      trackContractNumberClick({
        contractId,
        supplierId: supplier?.id || 0,
        supplierHandle: supplier?.handle || "",
        contractNumber: solicitation.contract_details?.contract_number || "",
      });
    };
    headerProps = {
      heading: `Email ${supplier?.displayName} about Contract # ${solicitation.contract_details?.contract_number}`,
      subheadingLinkProps: {
        text: solicitation?.title || "",
        url: contractUrl.toString(),
        analyticsClassName:
          "analytics-message-supplier-contract-name line-clamp-1",
      },
      subheading: solicitation.buyer_lead_agency
        ? `Shareable contract from ${solicitation.buyer_lead_agency}`
        : "",
    };
    messageSupplierSource = MessageSupplierSource.CONTRACT;
    initialSuppliersToContact = [
      {
        supplier,
        contact: {
          fullName: supplierContact.fullName || "",
          title: supplierContact.title || "",
        },
        contractId,
        supplierAgreement: supplierAgreement,
        contractIsPro,
      },
    ];
    supplierChipInputProps = {
      readOnlySupplierHandles: [supplier?.handle || ""],
      numSuppliers: 1,
    };
    messageFormProps = {
      supplierDisplayName: supplier?.displayName || "",
      initialMessage: getPrefillText({ isRecommendationPage: true }),
      goBackUrl: contractUrl,
      messageSupplierSource,
    };
    onSuccessUrl = getMessageSupplierOnSolicitationUrl({
      solicitationId: id || "",
      contractId,
      zip: null,
      query,
      successPage: true,
      requestID,
    });
    extraContractInfo = {
      contractId,
      solicitationId: id || "",
      initialSupplierForQuery: {
        contractId: solicitation.contract_details?.contract_id || "",
        title: "",
        expirationDate:
          solicitation.contract_details?.expiration_date?.toString() || "",
        supplier: supplier,
        supplierContact: {
          fullName:
            solicitation.contract_details?.supplier_contact.full_name || "",
          isDefault: false,
          title: "",
        },
        percentMatch: 0,
        supplierCompliance: supplierCompliance,
        supplierDisplay: supplierDisplay,
        supplierAgreement: supplierAgreement,
      },
    };
  } else if (_get(container, "dataset.recommended_suppliers")) {
    contractId = container.dataset.selectedContract || "";
    const suppliers: MessageSupplierData[] = JSON.parse(
      container.dataset.recommended_suppliers || ""
    );
    const selectedSuppliers = suppliers.filter(({ saved }) => {
      return !!saved;
    });
    const supplierDisplayName = selectedSuppliers[0].supplier.displayName;
    headerProps = {
      heading: "Email additional suppliers",
    };
    messageSupplierSource = MessageSupplierSource.RECOMMENDATION;
    initialSuppliersToContact = selectedSuppliers;
    supplierChipInputProps = {
      readOnlySupplierHandles: [],
      numSuppliers: suppliers.length,
    };
    messageFormProps = {
      supplierDisplayName,
      initialMessage: getPrefillText({ isRecommendationPage: true }),
      goBackUrl: contractUrl,
      messageSupplierSource,
    };
    onSuccessUrl = new URL("/message/success", getWindowLocationHref());
    extraContractInfo = {
      contractId,
      solicitationId: id || "",
    };
    otherSupplierModalFromListProps = {
      supplierList: suppliers,
    };
  } else if (_get(container, "dataset.supplier")) {
    const supplierData: {
      supplier: BaseSupplier;
      supplierCompliance: SupplierCompliance;
      supplierDisplay: SupplierDisplay;
      supplierAgreement: SupplierAgreementData;
      supplierContact: Maybe<MessageSupplierContact>;
    } = JSON.parse(container.dataset.supplier || "");
    ({ supplier, supplierCompliance, supplierDisplay, supplierAgreement } =
      supplierData);

    supplierContact = supplierData.supplierContact || {
      fullName: "",
      title: "",
    };
    const subheading =
      !!supplier?.addressCity && !!supplier.addressStateCode
        ? `${supplier.addressCity}, ${supplier.addressStateCode}\n`
        : `${supplierDisplay?.numActiveContracts} contracts available`;

    headerProps = {
      heading: `Email ${supplier?.displayName}`,
      subheading,
    };

    const sourceFromParam = getParam(
      "messageSupplierSource"
    ) as MessageSupplierSource;
    messageSupplierSource = Object.values(MessageSupplierSource).includes(
      sourceFromParam
    )
      ? sourceFromParam
      : MessageSupplierSource.SUPPLIER;
    initialSuppliersToContact = [
      {
        supplier,
        contact: {
          fullName: supplierContact.fullName || "",
          title: supplierContact.title || "'",
        },
        contractId,
        supplierAgreement: supplierAgreement,
      },
    ];
    supplierChipInputProps = {
      readOnlySupplierHandles: supplier?.handle ? [supplier.handle] : [],
      numSuppliers: 1,
    };
    messageFormProps = {
      supplierDisplayName: supplier?.displayName || "",
      initialMessage: getPrefillText({ isRecommendationPage: true }),
      goBackUrl: getSupplierUrlPath(
        supplier?.handle || "",
        query,
        null,
        pageNavigationSourceTypes.MESSAGE_SUPPLIER_PAGE
      ),
      messageSupplierSource,
    };
    onSuccessUrl = getMessageSupplierUrl({
      handle: supplier?.handle || "",
      query,
      zip: null,
      successPage: true,
      requestID,
    });
    extraSupplierInfo = {
      id: supplier?.id || 0,
      handle: supplier?.handle || "",
      contact: {
        full_name: supplierContact.fullName || "",
      },
    };
  } else {
    const data: {
      projectId: string;
      projectName: string;
      suppliers: MessageSupplierData[];
    } = JSON.parse(container.dataset.project || "");
    const supplierDisplayName = data.suppliers[0].supplier.displayName;
    const numSuppliers = data.suppliers.length;
    const heading =
      numSuppliers > 1
        ? `Email ${supplierDisplayName} and ${
            numSuppliers - 1
          } more ${pluralize("supplier", numSuppliers - 1)}`
        : `Email ${supplierDisplayName}`;

    const projectUrl = `/projects/${data.projectId}`;

    headerProps = {
      heading,
      subheadingLinkProps: {
        text: data.projectName,
        url: projectUrl,
        analyticsClassName: "analytics-back-to-project",
      },
    };
    messageSupplierSource = MessageSupplierSource.PROJECT;
    initialSuppliersToContact = data.suppliers;
    supplierChipInputProps = {
      readOnlySupplierHandles: [],
      numSuppliers,
    };
    messageFormProps = {
      supplierDisplayName,
      initialMessage: getPrefillText({ isRecommendationPage: true }),
      goBackUrl: new URL(
        `/projects/${data.projectId}`,
        getWindowLocationHref()
      ),
      messageSupplierSource: MessageSupplierSource.PROJECT,
    };
    onSuccessUrl = new URL("/message/success", getWindowLocationHref());
  }

  const root = createRoot(container);

  root.render(
    <Page
      pageType={PageType.SOLICITATION}
      pageId={id}
      searchSource="solicitation-header-search-bar"
      profileType={null}
      activeAgreements={null}
    >
      <SharedMessageSupplierPage
        headerProps={headerProps}
        messageSupplierSource={messageSupplierSource}
        initialSuppliersToContact={initialSuppliersToContact}
        supplierChipInputProps={supplierChipInputProps}
        messageFormProps={messageFormProps}
        onSuccessUrl={onSuccessUrl}
        extraContractInfo={extraContractInfo}
        extraSupplierInfo={extraSupplierInfo}
        otherSupplierModalFromListProps={otherSupplierModalFromListProps}
      />
    </Page>
  );
}
