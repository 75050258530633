import clsx from "clsx";
import { useRecoilCallback, useRecoilValue } from "recoil";

import useFilteredSearchResults from "../../../hooks/search/useFilteredSearchResults";
import useDiversityPreferences from "../../../hooks/useDiversityPreferences";
import useLoginWall from "../../../hooks/useLoginWall";
import {
  allSearchesLoadedState,
  contractDocumentsFilterState,
  contractSearchPageState,
  contractSearchResponseDataState,
  contractSourceFilterState,
  diversityCertificationsFilterState,
  expirationFilterState,
  matchedSearchResultCountState,
  requestIDState,
  searchQueryState,
  searchResultTypeState,
} from "../../../recoil/search";
import { userZipState } from "../../../recoil/user";
import { CONTRACT_SEARCH_PAGE_LENGTH } from "../../../utils/constants";
import { LoginWallTriggers } from "../../../utils/enums";
import { trackSearchPaginate } from "../../../utils/tracking";
import { LoadingCards } from "../../Loading/LoadingCards";
import ViewMoreButton from "../../ViewMoreButton";
import NoExactMatches from "../NoExactMatches";
import OtherResultsHeaderText from "./OtherResultsHeaderText";
import PrimaryResultsHeaderText from "./PrimaryResultsHeaderText";
import SearchResultList from "./SearchResultList";

function SearchResults() {
  const diversityPreferences = useDiversityPreferences();

  const userZip = useRecoilValue(userZipState);
  const requestID = useRecoilValue(requestIDState);
  const searchResultType = useRecoilValue(searchResultTypeState);
  const requireLogin = useLoginWall();
  const isLoading = !useRecoilValue(allSearchesLoadedState);
  const contractResponseData = useRecoilValue(contractSearchResponseDataState);
  const numAllResults = contractResponseData?.contractData?.numAllResults || 0;

  const results = contractResponseData?.contractData?.results || [];
  const blaMatchNextUrl = contractResponseData?.agencyData?.agency?.url;
  const queryLocation = contractResponseData?.metadata?.queryLocation;
  const matchedSearchResultCount = useRecoilValue(
    matchedSearchResultCountState
  );
  const page = useRecoilValue(contractSearchPageState);
  const query = useRecoilValue(searchQueryState);

  const filteredResults = useFilteredSearchResults({
    results,
    filterLowSimilarityResults: true,
    filterUnresponsiveContacts: true,
  });
  const hasMore = (page + 1) * CONTRACT_SEARCH_PAGE_LENGTH < numAllResults;

  const visibleResults = filteredResults.slice(
    0,
    (page + 1) * CONTRACT_SEARCH_PAGE_LENGTH
  );

  const primaryResults = visibleResults.slice(0, matchedSearchResultCount);
  const otherResults = visibleResults.slice(matchedSearchResultCount);

  const getNextSearchResultsPage = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        // Add login wall for BLA match page
        const [
          data,
          diversityCertificationsFilter,
          contractSourceFilter,
          contractDocumentsFilter,
          expirationFilter,
          page,
        ] = await Promise.all([
          snapshot.getPromise(contractSearchResponseDataState),
          snapshot.getPromise(diversityCertificationsFilterState),
          snapshot.getPromise(contractSourceFilterState),
          snapshot.getPromise(contractDocumentsFilterState),
          snapshot.getPromise(expirationFilterState),
          snapshot.getPromise(contractSearchPageState),
        ]);
        if (!data) return;
        const blaMatchNextUrl = data?.agencyData?.agency?.url;
        if (blaMatchNextUrl) {
          // @ts-ignore
          void requireLogin({
            onComplete: async () => {
              window.open(blaMatchNextUrl);
            },
            subtitle: "You must login or sign up to view more contracts",
            triggerType: LoginWallTriggers.CONTRACT_SEARCH_BLA_CLICK,
            triggerId: query,
          });
        } else {
          const newPage = page + 1;
          trackSearchPaginate({
            queryZip: userZip,
            requestID: data.params?.requestId || "",
            searchQuery: data.params?.query || "",
            expirationFilter,
            diversityCertificationsFilter,
            contractSourceFilter,
            contractDocumentsFilter,
            page: newPage,
          });
          set(contractSearchPageState, newPage);
        }
      },
    [requireLogin, blaMatchNextUrl, userZip]
  );

  const hasSupplierResults = searchResultType === "supplier";
  if (isLoading) {
    // Search API calls have not returned yet; showing empty placeholders for results
    return (
      <div className="grid w-full gap-8">
        <LoadingCards />
      </div>
    );
  }

  if (!results || results.length === 0) {
    return null;
  }

  const listClass = clsx("flex flex-col", {
    "gap-12": hasSupplierResults,
    "gap-6": !hasSupplierResults,
  });
  return (
    <div className="grid gap-14 w-full">
      <div className="flex flex-col gap-8">
        {!!matchedSearchResultCount && (
          <div className={listClass}>
            <PrimaryResultsHeaderText
              visibleResults={visibleResults}
              primaryResults={primaryResults}
              otherResults={otherResults}
            />
            <SearchResultList
              results={primaryResults}
              diversityPreferences={diversityPreferences}
              primary
              query={query}
              zip={userZip}
              queryLocation={queryLocation}
              requestID={requestID}
              numAllResults={numAllResults}
              showDividers={hasSupplierResults}
            />
            <NoExactMatches />
          </div>
        )}
        {!!otherResults.length && (
          <div className={listClass}>
            <OtherResultsHeaderText query={query} />
            <SearchResultList
              results={otherResults}
              primary={false}
              diversityPreferences={diversityPreferences}
              query={query}
              zip={userZip}
              queryLocation={queryLocation}
              requestID={requestID}
              numAllResults={numAllResults}
              showDividers={hasSupplierResults}
            />
          </div>
        )}
      </div>
      {hasMore && (
        <div className="w-full text-center">
          <ViewMoreButton
            className={`analytics-view-more-search-results more-btn-${page}`}
            isLoading={false}
            onClick={getNextSearchResultsPage}
          />
        </div>
      )}
    </div>
  );
}

export default SearchResults;
