import { atom } from "recoil";

import type { ItemTypeEnum } from "../generated";

export const savedProjectState = atom<{
  id: string;
  type: ItemTypeEnum;
} | null>({
  key: "savedProjectIdState",
  default: null,
});
