import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { modalState, pageTypeState } from "../recoil/page";
import { widgetSearchSourceState } from "../recoil/search";
import {
  isAuthenticatedState,
  userTypeSignupState,
  userZipState,
} from "../recoil/user";
import {
  type LoginWallTriggers,
  accountModals,
  agencies,
  agencySearchSource,
  type loginSignupAccountTypes,
} from "../utils/enums";
import { trackHeapEvent } from "../utils/tracking";

import { useAtomValue } from "jotai";
import useInitializeUser from "./useInitializeUser";

export const WHITELISTED_SEARCH_SOURCE = [
  agencySearchSource[agencies.BONFIRE_SERP],
  agencySearchSource[agencies.IONWAVE_SERP],
];

interface LoginWallOptions {
  title?: string;
  subtitle?: string;
  userType?: loginSignupAccountTypes;
  isBlocking?: boolean;
  supplierLogoUrl?: string;
  triggerType?: LoginWallTriggers;
  triggerId?: string;
  onComplete?: (...args: unknown[]) => void;
}

/**
 * Wraps a login wall around a function cb. Once a user has logged in, the
 * login modal is cleared and the initially blocked action is executed.
 */
export default function useLoginWall() {
  const [isAuthenticated, setIsAuthenticated] =
    useRecoilState(isAuthenticatedState);
  const setCurrentModal = useSetRecoilState(modalState);
  const setUserType = useSetRecoilState(userTypeSignupState);

  const pageType = useAtomValue(pageTypeState);
  const widgetSearchSource = useRecoilValue(widgetSearchSourceState);
  const searchZip = useRecoilValue(userZipState);

  const initializeUser = useInitializeUser();

  return async ({
    title,
    subtitle,
    userType,
    isBlocking = false,
    supplierLogoUrl,
    triggerType,
    triggerId,
    onComplete,
  }: LoginWallOptions = {}) => {
    if (
      (isAuthenticated ||
        WHITELISTED_SEARCH_SOURCE.includes(widgetSearchSource) ||
        widgetSearchSource === agencySearchSource[agencies.NAEP]) &&
      onComplete
    ) {
      await Promise.resolve(onComplete());
      return;
    }

    if (userType) {
      setUserType(userType);
    }

    const onCompleteArg = {
      onComplete: () => {
        window.location.hash = "";
        window.location.reload();
      },
    };
    if (onComplete) {
      onCompleteArg.onComplete = async () => {
        // clear the login or signup modal
        setCurrentModal({});
        setIsAuthenticated(true);
        await initializeUser();
        // do the blocked action after login or signup
        if (onComplete) {
          await Promise.resolve(onComplete());
        }
      };
    }

    // TODO: If this tracker is ever called anywhere else it should be moved
    //  to tracking.js and be assigned a schema.
    trackHeapEvent("require-login", {
      pageType,
      searchZip,
      widgetSearchSource,
      triggerType,
      triggerId,
    });
    setCurrentModal({
      name: accountModals.REQUIRE_LOGIN,
      subtitle:
        subtitle ||
        "Signing in helps Pavilion connect you with contract results tailored to your entity",
      title,
      isBlocking,
      supplierLogoUrl,
      hideUserTypeToggle: !!userType,
      ...onCompleteArg,
    });
  };
}
