import { useRecoilCallback } from "recoil";

import { userEmailVerifiedState } from "../recoil/user";
import { getUserEmailVerified } from "../utils/api";
import { handleError } from "../utils/generatedApi";

export default function useUpdateEmailVerified() {
  return useRecoilCallback(
    ({ set }) =>
      async () => {
        try {
          const verified = await getUserEmailVerified();
          set(userEmailVerifiedState, verified);
          return verified;
        } catch (err) {
          handleError(err);
        }
        return false;
      },
    []
  );
}
