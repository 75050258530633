import pluralize from "pluralize";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import { useAtomValue } from "jotai";
import type {
  SimilarContract,
  TrackContractClickWithinSolicitationCarouselFn,
} from "../../components/SolicitationPage/types";
import { pageIdState, pageTypeState } from "../../recoil/page";
import { userInitializedState, userZipState } from "../../recoil/user";
import {
  type RecommendationRequestData,
  getRecommendationContract,
  handleError,
} from "../../utils/api";
import { formatLocal } from "../../utils/format";
import SolicitationCarousel from "./SolicitationCarousel";

interface SemanticRecommendationCarouselProps {
  id: string;
  query: Maybe<string>;
  onRender: (solicitations: SimilarContract[]) => void;
  trackContractClickWithinSolicitation?: TrackContractClickWithinSolicitationCarouselFn;
}

export default function SemanticRecommendationCarousel({
  id,
  query,
  onRender,
  trackContractClickWithinSolicitation,
}: SemanticRecommendationCarouselProps): JSX.Element | null {
  const userZip = useRecoilValue(userZipState);
  const isInitialized = useRecoilValue(userInitializedState);
  const pageType = useAtomValue(pageTypeState);
  const pageId = useAtomValue(pageIdState);
  const [recommendations, setRecommendations] = useState<SimilarContract[]>();
  const [geo, setGeo] = useState<Maybe<string>>(null);
  const getCarouselTitle = () => {
    return `Explore similar ${pluralize(
      "contract",
      recommendations?.length
    )}${geo ? ` in ${geo}` : ""}`;
  };

  useEffect(() => {
    if (!isInitialized) return;

    const body: RecommendationRequestData = { id, size: 4 };
    if (userZip) body.zip = userZip;

    (async () => {
      const response = await getRecommendationContract(body);
      if (handleError(response)) return;

      const data = await response.json();

      if (data.results) {
        const { results, query_location } = data;

        if (query_location) {
          const { state, city, zip } = query_location;
          if (state || city) {
            setGeo(formatLocal({ state, city, zip }));
          }
        }
        setRecommendations(results);
      }
    })();
  }, [id, userZip, isInitialized]);

  if (!recommendations) {
    return null;
  }

  return (
    <SolicitationCarousel
      solicitations={recommendations}
      sourceData={{ pageType, pageId }}
      query={query}
      queryZip={userZip}
      onRender={onRender}
      trackContractClick={trackContractClickWithinSolicitation}
      title={getCarouselTitle()}
    />
  );
}
