import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ConstructionOutlinedIcon from "@mui/icons-material/ConstructionOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import Diversity1OutlinedIcon from "@mui/icons-material/Diversity1Outlined";
import EmojiEventsRoundedIcon from "@mui/icons-material/EmojiEventsRounded";
import EqualizerOutlinedIcon from "@mui/icons-material/EqualizerOutlined";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import LocationOffOutlinedIcon from "@mui/icons-material/LocationOffOutlined";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import ScheduleRoundedIcon from "@mui/icons-material/ScheduleRounded";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";
import clsx from "clsx";
import _get from "lodash/get";
import type { ElementType, ReactNode } from "react";

import { Badge, Typography } from "../../library";
import { withTooltip } from "../../library/Tooltip";
import type { TypographyColor } from "../../library/Typography/types";
import {
  type IconColor,
  bgColorClass,
  iconColorClass,
} from "../../utils/colors";
import { PlaceholderIcon } from "../CustomIcons/PlaceholderIcon";

// This should be a superset of VariantEnum from the generated code
export enum TagVariants {
  DIVERSITY = "diversity",
  OUT_OF_AREA = "outOfArea",
  NO_CONTRACTS = "noContracts",
  PLACEHOLDER = "placeholder",
  LOCATION = "location",
  WARNING = "warning",
  VERIFIED = "verified",
  PRICING_DOC = "pricingDoc",
  MISSING_CONTRACT_DOC = "missingContractDoc",
  MISSING_PRICING_DOC = "missingPricingDoc",
  MISSING_RFP_BID_DOC = "missingRfpBidDoc",
  NEW_FEATURE = "newFeature",
  NEW_FEATURE_DARK = "newFeatureDark",
  YOUR_CONTRACT = "yourContract",
  NEW_VIEWS = "newViews",
  PRO_SUPPLIER = "guaranteedResponse",
  EXPIRED = "expired",
  UNSUPPORTED = "unsupported",
  NON_COOPERATIVE = "nonCooperative",
  VERIFIED_CONTACT = "verifiedContact",
  LOCAL_SUPPLIER = "localSupplier",
  YEARS_IN_BUSINESS = "yearsInBusiness",
  MAX_DELIVERY_TIME = "maxDeliveryTime",
  SERVICE_PROVIDED = "serviceProvided",
  MADE_IN_USA = "madeInUsa",
  BEST_PRICE_GUARANTEE = "bestPriceGuarantee",
  FREE_CONSULTATION = "freeConsultation",
  FAMILY_OWNED = "familyOwned",
  GOVERNMENT_DISCOUNT = "governmentDiscount",
  RANKED_FIRST_ON_CONTRACT = "rankedFirstOnContract",

  // TODO: Check with Design to see if the following variants should be deprecated
  DEBUG_MATCH = "debugMatchTier",
  DEBUG_BLA = "debugBLA",
  DEBUG_PRO = "debugPro",
  DEBUG_INFO = "debugInfo",
  BEST_MATCH = "bestMatch",
  SUBTLE = "subtle",
}

export const tagClassName = (variant: TagVariants, className: Maybe<string>) =>
  clsx(
    "whitespace-nowrap flex flex-row items-center py-1 px-2 rounded-3 w-fit inline-flex",
    VARIANTS[variant].bgColorClass || VARIANT_DEFAULTS.bgColorClass,
    className
  );

const VARIANT_DEFAULTS: {
  bgColorClass: string;
  iconColor: IconColor;
  textColor: TypographyColor;
} = {
  bgColorClass: bgColorClass.brand.subtler.enabled,
  iconColor: "neutral.bold.enabled",
  textColor: "neutral.bolder.enabled",
};

const VARIANTS: Record<
  TagVariants,
  {
    bgColorClass?: string;
    Icon: ElementType | undefined;
    iconColor?: IconColor;
    textColor?: TypographyColor;
  }
> = {
  [TagVariants.DIVERSITY]: {
    Icon: FavoriteBorderRoundedIcon,
  },
  [TagVariants.OUT_OF_AREA]: {
    Icon: LocationOffOutlinedIcon,
  },
  [TagVariants.NO_CONTRACTS]: {
    bgColorClass: bgColorClass.accent.neutral.enabled,
    Icon: WarningAmberRoundedIcon,
  },
  [TagVariants.PLACEHOLDER]: {
    Icon: PlaceholderIcon,
  },
  [TagVariants.LOCATION]: {
    Icon: FmdGoodOutlinedIcon,
  },
  [TagVariants.EXPIRED]: {
    Icon: WarningAmberRoundedIcon,
  },
  [TagVariants.UNSUPPORTED]: {
    Icon: WarningAmberRoundedIcon,
  },
  [TagVariants.NON_COOPERATIVE]: {
    Icon: WarningAmberRoundedIcon,
  },
  [TagVariants.WARNING]: {
    Icon: WarningAmberRoundedIcon,
  },
  [TagVariants.VERIFIED]: {
    Icon: GppGoodOutlinedIcon,
  },
  [TagVariants.NEW_VIEWS]: {
    Icon: AutoAwesomeOutlinedIcon,
  },
  [TagVariants.PRICING_DOC]: {
    Icon: RequestQuoteOutlinedIcon,
  },
  [TagVariants.MISSING_CONTRACT_DOC]: {
    Icon: WarningAmberRoundedIcon,
  },
  [TagVariants.MISSING_PRICING_DOC]: {
    Icon: RequestQuoteOutlinedIcon,
  },
  [TagVariants.MISSING_RFP_BID_DOC]: {
    Icon: DescriptionOutlinedIcon,
  },
  [TagVariants.NEW_FEATURE]: {
    Icon: AutoAwesomeOutlinedIcon,
  },
  [TagVariants.NEW_FEATURE_DARK]: {
    bgColorClass: bgColorClass.brand.boldest.enabled,
    Icon: AutoAwesomeOutlinedIcon,
    iconColor: "neutral.subtlest.enabled",
    textColor: "neutral.subtlest.enabled",
  },
  [TagVariants.YOUR_CONTRACT]: {
    Icon: StarBorderRoundedIcon,
  },
  [TagVariants.PRO_SUPPLIER]: {
    Icon: ScheduleRoundedIcon,
  },
  [TagVariants.DEBUG_MATCH]: {
    Icon: WorkspacePremiumOutlinedIcon,
  },
  [TagVariants.DEBUG_BLA]: {
    Icon: AccountBalanceOutlinedIcon,
  },
  [TagVariants.DEBUG_PRO]: {
    Icon: AutoAwesomeOutlinedIcon,
  },
  [TagVariants.DEBUG_INFO]: {
    Icon: EqualizerOutlinedIcon,
  },
  // TODO: Check with Design to see if the following variants should be deprecated
  [TagVariants.BEST_MATCH]: {
    bgColorClass: bgColorClass.accent.default.secondary.enabled,
    Icon: CheckRoundedIcon,
  },
  [TagVariants.SUBTLE]: {
    bgColorClass: bgColorClass.accent.default.quinary.enabled,
    Icon: undefined,
  },
  [TagVariants.VERIFIED_CONTACT]: {
    Icon: GppGoodOutlinedIcon,
  },
  [TagVariants.LOCAL_SUPPLIER]: {
    Icon: StorefrontOutlinedIcon,
  },
  [TagVariants.YEARS_IN_BUSINESS]: {
    Icon: StorefrontOutlinedIcon,
  },
  [TagVariants.MAX_DELIVERY_TIME]: {
    Icon: LocalShippingOutlinedIcon,
  },
  [TagVariants.SERVICE_PROVIDED]: {
    Icon: ConstructionOutlinedIcon,
  },
  [TagVariants.MADE_IN_USA]: {
    Icon: HomeOutlinedIcon,
  },
  [TagVariants.BEST_PRICE_GUARANTEE]: {
    Icon: WorkspacePremiumOutlinedIcon,
  },
  [TagVariants.FREE_CONSULTATION]: {
    Icon: ForumOutlinedIcon,
  },
  [TagVariants.FAMILY_OWNED]: {
    Icon: Diversity1OutlinedIcon,
  },
  [TagVariants.GOVERNMENT_DISCOUNT]: {
    Icon: LocalOfferOutlinedIcon,
  },
  [TagVariants.RANKED_FIRST_ON_CONTRACT]: {
    Icon: EmojiEventsRoundedIcon,
  },
};

export interface TagProps {
  children: ReactNode;
  Icon?: ElementType;
  variant: TagVariants;
  size?: "sm" | "md";
  className?: string;
  showIcon?: boolean;
}

function Tag({
  children,
  Icon,
  variant,
  size = "sm",
  className,
  showIcon = true,
}: TagProps) {
  const { Icon: VariantIcon } = VARIANTS[variant];
  const iconColor = VARIANTS[variant].iconColor || VARIANT_DEFAULTS.iconColor;
  const textColor = VARIANTS[variant].textColor || VARIANT_DEFAULTS.textColor;
  return (
    <Badge
      as={Typography}
      Icon={showIcon ? Icon || VariantIcon : undefined}
      iconClass={_get(iconColorClass, iconColor) as string}
      linkProps={{
        variant: "meta",
        color: textColor,
        emphasis: true,
        component: "div",
      }}
      size={size}
      className={tagClassName(variant, className)}
    >
      {children}
    </Badge>
  );
}

export default withTooltip(Tag, {
  className: "whitespace-nowrap",
  placement: "top",
});
