import type { FormikValues } from "formik";
import { useEffect, useState } from "react";
import * as yup from "yup";

import { useSetAtom } from "jotai";
import type { ProjectCreationSource } from "../../components/Project/types";
import { ApiService, ItemTypeEnum } from "../../generated";
import useShowModal from "../../hooks/useShowModal";
import { LabeledInputVariants, Link, Typography } from "../../library";
import { InlineFormWrapper, LabeledInputField } from "../../library/form";
import { popupState } from "../../recoil/page";
import Modal from "../../shared/Modal/Modal";
import type { ShowModalCallbackType } from "../../shared/SaveToProjectButton";
import { BUTTON_PROPS } from "../../shared/saveToProjectUtils";
import { PopupType, modals } from "../../utils/enums";
import { handleError } from "../../utils/generatedApi";
import { trackNewProject } from "../../utils/tracking";

interface NewProjectModalProps {
  hideModal: () => void;
  source: ProjectCreationSource;
  itemId?: string;
  itemType?: ItemTypeEnum;
  initialProjectName?: string;
  title?: string;
  subtitle?: string;
  newProjectPrompt?: boolean;
  showOutreachModal?: ShowModalCallbackType;
  onCreate?: (projectId: string) => void;
}

const FIELDS = [
  [
    {
      name: "projectName",
      component: LabeledInputField,
      label: "Project name",
      className: "analytics-new-project-text",
      dataTestId: "new-project-text",
      initialVariant: LabeledInputVariants.DEFAULT,
      validate: yup.string().required("Name is required."),
    },
  ],
];

export function useShowNewProjectModal() {
  const show = useShowModal(modals.NEW_PROJECT_MODAL);
  return (props: Omit<NewProjectModalProps, "hideModal">) => show(props);
}

export default function NewProjectModal({
  hideModal,
  source,
  itemId,
  itemType,
  initialProjectName = "",
  title = "Create a new project",
  subtitle,
  newProjectPrompt, // "true" if we're nudging the user to create a Project (slightly different UI)
  showOutreachModal,
  onCreate,
}: NewProjectModalProps) {
  const [error, setError] = useState("");
  const [projectName, setProjectName] = useState(initialProjectName);
  const setPopupState = useSetAtom(popupState);
  // biome-ignore lint/correctness/useExhaustiveDependencies: Fetch once on mount.
  useEffect(() => {
    if (!(itemId && itemType)) {
      return;
    }
    (async () => {
      try {
        const response = await ApiService.apiV1ProjectsNameSuggestionCreate({
          contractId: itemType === ItemTypeEnum.CONTRACT ? itemId : undefined,
          supplierId:
            itemType === ItemTypeEnum.SUPPLIER
              ? Number.parseInt(itemId, 10)
              : undefined,
        });
        setProjectName(response.suggestion);
      } catch (e) {
        handleError(e);
      }
    })();
  }, []);

  async function createProject(values: FormikValues) {
    try {
      const project = await ApiService.apiV1ProjectsCreate({
        name: values.projectName,
      });

      trackNewProject({ source });

      let bannerCopy = `Your project "${values.projectName}" has been created.`;
      if (project.id && onCreate) {
        onCreate(project.id);
        if (itemType) bannerCopy = BUTTON_PROPS[itemType].viewSavedCtaCopy;
      }

      setPopupState({
        analyticsClassName: "analytics-create-projects-success-badge",
        name: PopupType.SUCCESS,
        durationSeconds: 5,
        children: (
          <Typography color="inverse">
            {bannerCopy}{" "}
            <Link
              emphasis={false}
              href={`/projects/${project.id}`}
              color="inverse"
              onClick={hideModal}
            >
              Click here to view it
            </Link>
            .
          </Typography>
        ),
        show: true,
      });
      hideModal();
    } catch (err) {
      handleError(err);
      setError(
        "Your project could not be created at this time, please try again later."
      );
      return;
    }
  }

  function onSubmit(values: FormikValues) {
    if (showOutreachModal) {
      showOutreachModal({
        onClickCta: () => createProject(values),
      });
    } else {
      createProject(values);
    }
  }

  let newProjectPromptFormParams = {};
  if (newProjectPrompt) {
    newProjectPromptFormParams = {
      submitCta: "Yes, save",
      secondaryCtaProps: {
        cta: "No, skip",
        onClick: hideModal,
        className: "analytics-skip-new-project-cta",
        align: "center",
      },
    };
  }

  return (
    <Modal
      hideModal={hideModal}
      title={title}
      subtitle={subtitle}
      error={error}
    >
      <InlineFormWrapper
        fields={FIELDS}
        onSubmit={onSubmit}
        initialValues={{ projectName }}
        disabled={false}
        submitClassName="analytics-save-new-project-cta"
        submitCta={showOutreachModal ? "Next" : "Save"}
        listener={(values: FormikValues) => {
          setProjectName(values.projectName);
        }}
        {...newProjectPromptFormParams}
      />
    </Modal>
  );
}
