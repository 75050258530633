import { captureMessage } from "@sentry/browser";
import { useSetAtom } from "jotai";
import { useRef, useState } from "react";
import { useRecoilCallback, useRecoilState, useRecoilValue } from "recoil";
import contactQuestionSingle from "../../../img/icons/contact-question-single.svg";
import contactQuestion from "../../../img/icons/contact-question.png";
import { MessageSupplierSource } from "../../components/MessageSupplierPage/types";
import { ProjectCreationSource } from "../../components/Project/types";
import { ApiError } from "../../generated";
import { ApiService } from "../../generated/services/ApiService";
import useSendEmailVerification from "../../hooks/useSendEmailVerification";
import useShowModal from "../../hooks/useShowModal";
import { IconRadioButton, LabeledInput, Link, Typography } from "../../library";
import type { BadgeProps } from "../../library/Badge";
import { popupState } from "../../recoil/page";
import {
  governmentAffiliationDisplayNameLocalState,
  userDetailsState,
  userEmailVerifiedState,
} from "../../recoil/user";
import Modal, { modalSizes } from "../../shared/Modal/Modal";
import { getRequestID } from "../../utils";
import {
  getUserEmailVerified,
  postContactEmailSupplier,
} from "../../utils/api";
import { GOV_EMAIL_REGEX } from "../../utils/constants";
import {
  MODAL_SOURCE,
  PopupType,
  accountModals,
  modals,
} from "../../utils/enums";
import {
  getErrorMessage,
  handleError as handleGeneratedError,
} from "../../utils/generatedApi";
import { isFeatureEnabled } from "../../utils/split";
import {
  trackContactSupplierGTM,
  trackContactSupplierHeap,
  trackNewProject,
  trackQuoteRequestOptInSubmit,
  trackSupplierOutreachOptInWithUnverifiedEmail,
} from "../../utils/tracking";
import SubmitButton from "../SignupSteps/SubmitButton";
import {
  modalSourceToContactSupplierHeapSource,
  modalSourceToInteractionTypeEnum,
  modalSourceToQuoteInteractionTypeEnum,
} from "./constants";
import type { SharedBuyerOptInModalProps } from "./types";

export interface BuyerOptInModalProps extends SharedBuyerOptInModalProps {
  title: string;
  subtitle: string;
  ctaText: string;
  ctaBadgeProps?: BadgeProps;
  analyticsClassName: string;
}

export type ShowBuyerOptInModal = (
  args: Omit<BuyerOptInModalProps, "hideModal">
) => void;

enum OptInModalOptions {
  QUOTE_REQUEST = "QUOTE_REQUEST",
  OPT_IN = "OPT_IN",
  NONE = "NONE",
}

export default function BuyerOptInModal({
  modalType,
  supplier,
  contractId,
  solicitationId,
  supplierPOC,
  query,
  contractTitle,
  onComplete,
  onSkip,
  downloadFiles,
  trackOptIn,
  trackOptOut,
  shouldHideModalAfterCta = true,
  hideModal,
  source,
  title,
  subtitle,
  ctaText,
  ctaBadgeProps,
  analyticsClassName,
}: BuyerOptInModalProps) {
  const [optionSelected, setOptionSelected] = useState<
    OptInModalOptions | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);
  const [requestDetails, setRequestDetails] = useState<string>("");
  const [{ firstName, lastName, email: buyerEmail }, setUserDetailState] =
    useRecoilState(userDetailsState);
  const userBLA = useRecoilValue(governmentAffiliationDisplayNameLocalState);
  const [emailChangeSuccess, setEmailChangeSuccess] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [emailVerificationError, setEmailVerificationError] =
    useState<string>("");
  const setPopupState = useSetAtom(popupState);
  const showVerifyEmailModal = useShowModal(modals.PLEASE_VERIFY_EMAIL_MODAL);
  const sendVerificationEmail = useSendEmailVerification({
    source: MODAL_SOURCE.BUYER_OPT_IN,
    onError: setEmailVerificationError,
  });
  const showChangeEmailModal = useShowModal(accountModals.CHANGE_EMAIL);
  const showBuyerOptInModal = useShowModal(modalType);
  const trackSupplierOutreachParams = {
    contractId,
    solicitationId,
    supplierHandle: supplier.handle,
    supplierId: supplier.id,
    userEmail: buyerEmail as string,
  };
  const interactionType = modalSourceToInteractionTypeEnum(source);
  const quoteRequestInteractionType =
    modalSourceToQuoteInteractionTypeEnum(source);
  const descriptionRef = useRef<HTMLInputElement>(null);
  const quoteDescriptionRef = useRef<HTMLInputElement>(null);
  const showQuoteRequestOption = isFeatureEnabled("quoteRequestOptIn");

  function focusDescription() {
    if (optionSelected === OptInModalOptions.QUOTE_REQUEST) {
      if (quoteDescriptionRef.current) {
        quoteDescriptionRef.current.focus();
      }
    } else if (optionSelected === OptInModalOptions.OPT_IN) {
      if (descriptionRef.current) {
        descriptionRef.current.focus();
      }
    }
  }

  function handleSkip() {
    trackOptOut(trackSupplierOutreachParams);
    onSkip();
    if (downloadFiles) downloadFiles();
    if (shouldHideModalAfterCta) {
      hideModal();
    }
  }

  async function handleSubmitOptIn() {
    const isVerifiedGovEmail = await checkEmail();
    if (!isVerifiedGovEmail) {
      trackSupplierOutreachOptInWithUnverifiedEmail(
        trackSupplierOutreachParams
      );
      return;
    }
    if (!buyerEmail) {
      return;
    }
    if (!interactionType) {
      // In theory, `source` shold always be defined, so
      // `modalSourceToInteractionTypeEnum` should always return a value.
      captureMessage("interactionType should not be null");
      return;
    }

    setIsLoading(true);
    try {
      await postContactEmailSupplier({
        contractId,
        suppliers: [supplier.handle],
        name: [firstName, lastName].join(" ").trim(),
        cc: [],
        email: buyerEmail,
        publicAgency: userBLA,
        description: requestDetails,
        interactionType,
      });
    } catch (err) {
      if (
        handleGeneratedError(err, {
          logToSentry: true,
          log400ErrorsToSentry: false,
        })
      ) {
        if (err instanceof ApiError && err.status === 400) {
          setErrorMessage(getErrorMessage(err));
        }
        setIsLoading(false);
        return;
      }
    }
    setIsLoading(false);
    _trackSendMessage();
    trackOptIn(trackSupplierOutreachParams);
    onComplete();
    if (downloadFiles) {
      downloadFiles();
    }
    setPopupState({
      analyticsClassName: `${analyticsClassName}-success`,
      name: PopupType.SUCCESS,
      children: (
        <Typography color="inverse">
          Supplier has been notified and will be in touch soon!
        </Typography>
      ),
      show: true,
    });
    if (shouldHideModalAfterCta) {
      hideModal();
    }
  }

  const checkEmail = useRecoilCallback<[], Promise<boolean>>(
    ({ snapshot, set }) =>
      async () => {
        const emailVerified = await snapshot.getPromise(userEmailVerifiedState);
        if (buyerEmail && !GOV_EMAIL_REGEX.test(buyerEmail)) {
          showChangeEmailModal({
            title: "Please Update Your Email",
            subtitle: `It looks like you're sending this message
              from a personal or non-government email address.
              Please update your email to a government email address.`,
            onComplete: (newEmail: string) => {
              setEmailChangeSuccess("You can send your message now.");
              setUserDetailState({
                email: newEmail,
                firstName,
                lastName,
              });
            },
            requireGovEmail: true,
            canSkip: false,
          });
          return false;
        }

        if (!emailVerified) {
          // We check email verified status again via an API call because a user could have
          // verified their email before a page reload, so then recoil state would not update.
          try {
            const verified = await getUserEmailVerified();

            if (!verified) {
              sendVerificationEmail(() =>
                showVerifyEmailModal({
                  onCtaClick: () =>
                    showBuyerOptInModal({
                      supplier,
                      contractId,
                      contractTitle,
                      solicitationId,
                      query,
                      supplierPOC,
                      source,
                      trackOptIn,
                      trackOptOut,
                      onSkip,
                      onComplete,
                      downloadFiles,
                      shouldHideModalAfterCta,
                    }),
                })
              );
              return false;
            }
            set(userEmailVerifiedState, verified);
          } catch (err) {
            if (handleGeneratedError(err)) {
              setErrorMessage(
                "Something went wrong. Please try verifying your email from your profile."
              );
              return false;
            }
          }
        }
        return true;
      }
  );

  async function handleSubmitQuoteRequest() {
    if (!buyerEmail) {
      return;
    }
    if (!quoteRequestInteractionType) {
      // In theory, `source` shold always be defined, so
      // `modalSourceToInteractionTypeEnum` should always return a value.
      captureMessage("quoteRequestInteractionType should not be null");
      return;
    }

    setIsLoading(true);

    try {
      const projectName = `Quote request for "${requestDetails}"`;
      await ApiService.apiV1ProjectsCreate({
        name: projectName,
      });

      trackNewProject({ source: ProjectCreationSource.QUOTE_REQUEST });

      await ApiService.apiV1QuoteRequestsCreate({
        description: requestDetails,
      });
    } catch (err) {
      if (
        handleGeneratedError(err, {
          logToSentry: true,
          log400ErrorsToSentry: false,
        })
      ) {
        if (err instanceof ApiError && err.status === 400) {
          setErrorMessage(getErrorMessage(err));
        }
        setIsLoading(false);
        return;
      }
    }

    trackQuoteRequestOptInSubmit({
      contractId,
      interactionType: quoteRequestInteractionType,
    });
    setIsLoading(false);
    onComplete();

    if (downloadFiles) {
      downloadFiles();
    }

    setPopupState({
      analyticsClassName: `${analyticsClassName}-success`,
      name: PopupType.SUCCESS,
      children: (
        <Typography color="inverse">
          We've received your request for quotes and will be in touch soon!
        </Typography>
      ),
      show: true,
    });

    if (shouldHideModalAfterCta) {
      hideModal();
    }
  }

  async function handleSubmit() {
    if (optionSelected === OptInModalOptions.QUOTE_REQUEST) {
      await handleSubmitQuoteRequest();
    } else if (optionSelected === OptInModalOptions.OPT_IN) {
      await handleSubmitOptIn();
    } else if (optionSelected === OptInModalOptions.NONE) {
      handleSkip();
    }
  }

  function _trackSendMessage() {
    const heapSource = modalSourceToContactSupplierHeapSource(source);
    trackContactSupplierGTM();
    if (!interactionType || !heapSource) return;

    trackContactSupplierHeap({
      contractId,
      solicitationId,
      supplierHandles: [supplier.handle],
      supplierIds: [supplier.id],
      email: buyerEmail,
      buyerLoc: userBLA || "",
      hasVerifiedContact: !!supplierPOC,
      supplierPOC: supplierPOC || "",
      promotedSupplierIds: [],
      promotedSupplierCount: 0,
      promotedSupplierHandles: [],
      ccEmails: [],
      numCcEmails: 0,
      source: heapSource,
      interactionType,
      messageSupplierSource: MessageSupplierSource.BUYER_OPT_IN,
      requestID: getRequestID(),
    });
  }

  return (
    <Modal
      modalSize={modalSizes.SMALL}
      hideModal={hideModal}
      title={title}
      subtitle={subtitle}
      centerSubtitle={false}
      className={analyticsClassName}
      isBlocking
    >
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-4">
          {showQuoteRequestOption && (
            <>
              <IconRadioButton
                isSelected={optionSelected === OptInModalOptions.QUOTE_REQUEST}
                onClick={() =>
                  setOptionSelected(OptInModalOptions.QUOTE_REQUEST)
                }
                label="I want quotes from 3 suppliers that sell what I need"
                border
              >
                <img className="h-10 mx-2" src={contactQuestion} />
              </IconRadioButton>
              {optionSelected === OptInModalOptions.QUOTE_REQUEST && (
                <LabeledInput
                  name="description"
                  label="What are you buying?"
                  value={requestDetails}
                  onChange={(e) => {
                    setRequestDetails(e.target.value);
                  }}
                  ref={quoteDescriptionRef}
                  size="md"
                  required
                  autoFocus
                />
              )}
            </>
          )}
          <IconRadioButton
            isSelected={optionSelected === OptInModalOptions.OPT_IN}
            onClick={() => setOptionSelected(OptInModalOptions.OPT_IN)}
            label={
              showQuoteRequestOption
                ? "I just want a quote from this supplier"
                : "Yes, supplier can email me pricing and contract details"
            }
            border
          >
            <img className="h-10 mx-2" src={contactQuestionSingle} />
          </IconRadioButton>
          {optionSelected === OptInModalOptions.OPT_IN && (
            <LabeledInput
              name="description"
              label="What are you buying?"
              value={requestDetails}
              onChange={(e) => {
                setRequestDetails(e.target.value);
              }}
              ref={descriptionRef}
              size="md"
              required
              autoFocus
            />
          )}
          <IconRadioButton
            isSelected={optionSelected === OptInModalOptions.NONE}
            onClick={() => setOptionSelected(OptInModalOptions.NONE)}
            label={
              showQuoteRequestOption
                ? "Don’t connect me with suppliers yet"
                : "No, I don't want pricing and contract details in my inbox"
            }
          />
        </div>
        <div
          onClick={(_e) => {
            optionSelected !== OptInModalOptions.NONE &&
              requestDetails === "" &&
              focusDescription();
          }}
        >
          <SubmitButton
            isCTALoading={isLoading}
            handleSubmit={handleSubmit}
            ctaText={ctaText}
            badgeProps={ctaBadgeProps}
            disabled={
              (optionSelected !== OptInModalOptions.NONE &&
                requestDetails === "") ||
              !optionSelected
            }
          />
        </div>
        <Typography size="sm" color="neutral.bold.enabled">
          Change your contact settings in{" "}
          <Link size="sm" emphasis={false} href="/profile#preferences">
            preferences
          </Link>
          .
        </Typography>
        {emailVerificationError ||
          (errorMessage && (
            <Typography color="feedback.bold.error">
              {emailVerificationError || errorMessage}
            </Typography>
          ))}
        {emailChangeSuccess && (
          <Typography color="feedback.bold.success">
            {emailChangeSuccess}
          </Typography>
        )}
      </div>
    </Modal>
  );
}
