import _compact from "lodash/compact";
import { useRecoilValue } from "recoil";
import {
  type BaseSupplier,
  type SupplierCompliance,
  type SupplierServiceAreaData,
  SupplierServiceAreaDataConfidenceEnum,
  VariantEnum,
} from "../../generated";
import { userState } from "../../recoil/user";
import { getSupplierTagOrder } from "../../utils/tags";
import {
  TYPE_TO_VARIANT,
  VARIANT_TO_TOOLTIP,
  filterAndSortTagsByOrder,
} from "../ContractBase/getTags";
import type { TagType } from "../ContractBase/types";
import { TagVariants } from "../Tag";

export const SUPPLIER_AGREEMENT_TO_TAG_TEXT: Record<string, string> = {
  UNLIMITED: "Referral Pro",
  CONTRACT_LIMITED: "Orange County Pro",
  VIEWS_LIMITED: "Membership Pro",
  TARGET: "EV Trial Pro",
};

export function getFormattedTagsForSupplier({
  compliance,
  serviceArea,
  activeAgreements,
  noActiveShareableContracts,
  supplierTagOrder = getSupplierTagOrder(),
}: {
  compliance?: SupplierCompliance;
  serviceArea?: SupplierServiceAreaData;
  activeAgreements?: string[];
  noActiveShareableContracts?: boolean;
  supplierTagOrder?: TagVariants[];
}) {
  const { isAdmin } = useRecoilValue(userState);
  const shouldAddServiceArea = compliance?.tags.some(
    ({ variant }) => variant === VariantEnum.LOCAL_SUPPLIER
  );
  const sortedComplianceTags = compliance?.tags
    ? filterAndSortTagsByOrder(compliance?.tags, supplierTagOrder)
    : [];

  let formattedTags: TagType[] =
    _compact(
      sortedComplianceTags.map((tag) => {
        const variant = TYPE_TO_VARIANT[tag.variant];
        if (variant) {
          return {
            text: tag.copy,
            variant,
            tooltip: VARIANT_TO_TOOLTIP[variant],
          };
        }
      })
    ) || [];

  if (noActiveShareableContracts) {
    formattedTags.push({
      text: "No usable contracts",
      variant: TagVariants.NO_CONTRACTS,
    });
  }

  if (compliance?.diversityCertifications.length) {
    formattedTags.push({
      text: "Diversity certifications",
      variant: TagVariants.DIVERSITY,
    });
  }

  if (
    shouldAddServiceArea &&
    !!serviceArea?.state &&
    !!serviceArea?.confidence &&
    serviceArea.confidence !== SupplierServiceAreaDataConfidenceEnum.NEGATIVE
  ) {
    formattedTags.push({
      text: `Serves ${serviceArea.state}`,
      variant: TagVariants.LOCATION,
    });
  }

  if (isAdmin && activeAgreements?.length) {
    formattedTags = formattedTags.concat(
      activeAgreements.map((text) => ({
        text: `${SUPPLIER_AGREEMENT_TO_TAG_TEXT[text]}`,
        variant: TagVariants.DEBUG_MATCH,
      }))
    );
  }

  return formattedTags;
}

export function dedupeSuppliers<T extends { supplier: BaseSupplier }>(
  list: T[]
) {
  const seenSuppliers = new Set();

  return list.filter((supplier) => {
    const duplicate = seenSuppliers.has(supplier.supplier.handle);
    seenSuppliers.add(supplier.supplier.handle);
    return !duplicate;
  });
}

export function getSupplierAddressSnippet(supplier: BaseSupplier) {
  return [supplier.addressCity, supplier.addressStateCode]
    .filter(Boolean)
    .join(", ");
}

export function getSupplierAliasSnippet({ aliases }: SupplierCompliance) {
  if (!aliases?.length) {
    return null;
  }
  if (aliases.length === 1) {
    return `Also known as ${aliases[0]}`;
  }
  if (aliases.length === 2) {
    return `Also known as ${aliases[0]} and ${aliases[1]}`;
  }
  if (aliases.length === 3) {
    return `Also known as ${aliases[0]}, ${aliases[1]}, and ${aliases[2]}`;
  }
  return `Also known as ${aliases[0]}, ${aliases[1]}, ${aliases[2]} and ${
    aliases.length - 3
  } more`;
}
