import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import { captureMessage } from "@sentry/browser";
import { useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";

import { useSetAtom } from "jotai";
import useLoginWall from "../../../hooks/useLoginWall";
import { Button, DropdownPicker, Typography } from "../../../library";
import { popupState } from "../../../recoil/page";
import { isAuthenticatedState } from "../../../recoil/user";
import type {
  DedupedSupplierContact,
  SupplierContactWithPhoneNumber,
} from "../../../shared/types";
import { getSupplierContact } from "../../../utils/api";
import {
  LoginWallTriggers,
  SupplierContactSourceType,
} from "../../../utils/enums";
import { formatPhoneNumber } from "../../../utils/format";
import { handleError } from "../../../utils/generatedApi";
import {
  getContactDisplayName,
  groupContactsByTitle,
} from "../SupplierEditForm/SupplierEditSection/helpers";
import { ContactNavigationButtons } from "./SupplierContactNavigationButtons";

export default function SupplierContactInfo({
  supplierId,
  supplierName,
  dedupedContacts,
}: {
  supplierId: number;
  supplierName: string;
  dedupedContacts: DedupedSupplierContact[];
}) {
  const requireLogin = useLoginWall();
  const isAuthenticated = useRecoilValue(isAuthenticatedState);
  const setPopupState = useSetAtom(popupState);
  // The currently selected title. When null, we show the supplier's first contact.
  // When a title is selected, we show all the contacts with this title.
  const [selectedTitle, setSelectedTitle] = useState<string | null>(null);

  // Index for the current deduped contact, which may have multiple phone numbers.
  // We keep track of this so we can call `getSupplierContact` for one contact
  // at a time, rather than all of them at once.
  const [contactIndex, setContactIndex] = useState(0);
  // Array of phone numbers for the current contact.
  const [contactPhones, setContactPhones] = useState<
    SupplierContactWithPhoneNumber[]
  >([]);
  // Index for the current phone number (technically also a `DedupedSupplierContact`)
  // for dedupedContacts[contactIndex]
  const [phoneIndex, setPhoneIndex] = useState(0);

  const contactsByTitle = useMemo(
    () => groupContactsByTitle(dedupedContacts),
    [dedupedContacts]
  );

  useEffect(() => {
    if (isAuthenticated) {
      getContactsWithPhoneNumbers({ index: 0 }).then((contacts) =>
        setContactPhones(contacts)
      );
    }
  }, [isAuthenticated]);

  async function getContactsWithPhoneNumbers({
    index,
  }: {
    index: number;
  }): Promise<SupplierContactWithPhoneNumber[]> {
    const contact = dedupedContacts[index];
    const responses = await Promise.all(
      contact.duplicatePhoneIds.map(async (contactId) => {
        const response = await getSupplierContact(supplierId, contactId);
        if (response.status === 200) return response.json();
        // This case should not happen, though this tracks if it does.
        if (response.status === 403)
          captureMessage("Invalid user requesting contact information.");
        else if (response.status === 404) {
          captureMessage(
            `No contact with id ${contactId} found for supplier ${supplierId}`
          );
        } else {
          handleError(response);
        }
        return null;
      })
    );
    return responses.filter((v) => !!v?.phoneNumber);
  }

  async function onShowVerifiedPhoneWithLoginWall() {
    await requireLogin({
      triggerId: `${dedupedContacts[contactIndex].id}`,
      triggerType: LoginWallTriggers.SOLICITATION_PAGE_VIEW_PHONE_NUMBER_CLICK,
    });
  }

  function onClickBack() {
    setPopupState(null);
    if (phoneIndex > 0) {
      setPhoneIndex((prev) => prev - 1);
    } else if (contactIndex > 0) {
      getContactsWithPhoneNumbers({ index: contactIndex - 1 }).then(
        (response) => {
          setContactIndex(contactIndex - 1);
          setContactPhones(response);
          setPhoneIndex(0);
        }
      );
    }
  }

  function onClickNext() {
    setPopupState(null);
    if (phoneIndex + 1 < contactPhones.length) {
      setPhoneIndex((prev) => prev + 1);
    } else if (contactIndex + 1 < dedupedContacts.length) {
      getContactsWithPhoneNumbers({ index: contactIndex + 1 }).then(
        (response) => {
          setContactIndex(contactIndex + 1);
          setContactPhones(response);
          setPhoneIndex(0);
        }
      );
    }
  }

  const selectedContactSet = selectedTitle
    ? contactsByTitle[selectedTitle]
    : dedupedContacts;
  const contactState = selectedContactSet[contactIndex];

  const displayName = getContactDisplayName(contactState, supplierName);
  const isVerified =
    contactState.source === SupplierContactSourceType.MANUAL_ENTRY;
  const verifiedText = isVerified ? " (verified)" : null;

  const titles = Object.keys(contactsByTitle);
  const titleOptions = titles.map((title) => ({
    key: title,
    value: title,
    label: <Typography>{title}</Typography>,
  }));

  const dropdownOptions = [
    {
      key: "all-contacts",
      value: "",
      label: "All contacts",
    },
    ...titleOptions,
  ];

  return (
    <div className="flex flex-col gap-4 h-fit">
      {titleOptions.length > 1 && (
        <DropdownPicker
          initialValue={""}
          options={dropdownOptions}
          onChange={(value) => setSelectedTitle(value)}
          className="w-full"
          buttonClassName="!px-3"
        />
      )}
      <div className="flex flex-col gap-1">
        <Typography
          emphasis
          color="neutral.boldest.enabled"
          className="capitalize"
        >
          {displayName}
          <Typography
            component="span"
            emphasis={false}
            color="neutral.bolder.enabled"
          >
            {verifiedText}
          </Typography>
        </Typography>
        {(contactState.firstName || contactState.lastName) &&
          contactState.title && (
            <Typography color="neutral.bolder.enabled" size="sm">
              {contactState.title}
            </Typography>
          )}
        {contactPhones.length > 0 &&
          contactPhones[phoneIndex]?.phoneNumber &&
          (isAuthenticated ? (
            <Typography size="sm" color="neutral.bolder.enabled">
              {formatPhoneNumber(contactPhones[phoneIndex].phoneNumber)}
            </Typography>
          ) : (
            <Button
              size={Button.sizes.SMALL}
              theme={Button.themes.TERTIARY_DARK}
              onClick={onShowVerifiedPhoneWithLoginWall}
              className="whitespace-nowrap w-fit"
              badgeProps={{
                Icon: LocalPhoneRoundedIcon,
                className: "gap-1",
              }}
            >
              View phone number
            </Button>
          ))}
      </div>
      {selectedContactSet.length > 1 && (
        <ContactNavigationButtons
          onClickBack={onClickBack}
          onClickNext={onClickNext}
          isBackDisabled={phoneIndex === 0 && contactIndex === 0}
          isNextDisabled={
            !contactPhones[phoneIndex + 1] &&
            !selectedContactSet[contactIndex + 1]
          }
          contactIndex={contactIndex}
          contactsCount={selectedContactSet.length}
        />
      )}
    </div>
  );
}
