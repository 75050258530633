import { useGroupContractFiles } from "../../../hooks/useGroupContractFiles";
import type { Bookmark, OCRFile } from "../../../shared/types";
import type { FileSection } from "../types";
import FileGroup from "./FileGroup";
import type { HandleClickBookmarkOptions } from "./FileItem";

interface FilesListProps {
  contractNumber?: string;
  currentFileIndex: number;
  files: OCRFile[];
  onClick: (file: OCRFile) => void;
  onClickBookmark: (file: OCRFile, bookmark: Bookmark) => void;
  onDownload: (file: OCRFile) => void;
  supplierName: string;
}

// Renders the file list and allows you to toggle changes
export default function FilesList({
  files,
  currentFileIndex,
  onClick,
  onClickBookmark,
  onDownload,
  contractNumber,
  supplierName,
}: FilesListProps) {
  function handleClickBookmark({ file, bookmark }: HandleClickBookmarkOptions) {
    onClickBookmark(file, bookmark);
  }
  const fileSections = useGroupContractFiles(files);

  return (
    <div className="analytics-files-sidebar-select-file grid gap-4">
      {fileSections.map((fileSection: FileSection) => (
        <FileGroup
          key={fileSection.header}
          analyticsClassName={fileSection.className}
          contractNumber={contractNumber}
          currentFileIndex={currentFileIndex}
          files={files}
          header={fileSection.header}
          onClick={onClick}
          handleClickBookmark={handleClickBookmark}
          onDownload={onDownload}
          sectionFiles={fileSection.files}
          supplierName={supplierName}
        />
      ))}
    </div>
  );
}
