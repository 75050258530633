import { Disclosure, Transition } from "@headlessui/react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import clsx from "clsx";
import {
  type MutableRefObject,
  type ReactNode,
  useEffect,
  useState,
} from "react";

import Badge from "../Badge";
import CardContainer, { CardContainerVariant } from "../CardContainer";

export interface AccordionItem {
  key: string;
  header: ReactNode;
  subheader: ReactNode;
  children: ReactNode;
}

interface DisclosureContentProps {
  close: (
    focusableElement?:
      | HTMLElement
      | MutableRefObject<HTMLElement | null>
      | undefined
  ) => void;
  selected: boolean;
  card: AccordionItem;
  onDisclosureClick: () => void;
}

function DisclosureContent({
  close,
  selected,
  card,
  onDisclosureClick,
}: DisclosureContentProps) {
  const [wasSelected, setWasSelected] = useState(selected);
  useEffect(() => {
    if (wasSelected && !selected) close();
    setWasSelected(selected);
  }, [selected, close, wasSelected]);

  return (
    <>
      <Disclosure.Button
        className="flex justify-between"
        onClick={onDisclosureClick}
      >
        <div className="flex flex-col gap-4 text-left">
          {card.header}
          {card.subheader}
        </div>
        <Badge
          Icon={selected ? ExpandLess : ExpandMoreRoundedIcon}
          iconClass="text-cp-lapis-500"
          size="lg"
        />
      </Disclosure.Button>
      <Transition
        show={wasSelected}
        enter="transition-all ease-in duration-1000"
        enterFrom="max-h-0"
        enterTo="max-h-screen"
        leave="transition-all ease-out duration-1000"
        leaveFrom="max-h-screen"
        leaveTo="max-h-0"
      >
        <div className="overflow-hidden">
          <Disclosure.Panel
            className="border-t pt-4 mt-4 border-solid border-cp-white-300"
            static
          >
            {card.children}
          </Disclosure.Panel>
        </div>
      </Transition>
    </>
  );
}

interface AccordionGroupProps {
  cards: AccordionItem[];
  initialKey?: Maybe<string>;
  className?: string;
}

export default function AccordionGroup({
  cards,
  initialKey = null,
  className,
}: AccordionGroupProps) {
  const [selectedCard, setSelectedCard] = useState<string | null>(initialKey);
  return (
    <div className={clsx("flex flex-col gap-6", className)}>
      {cards.map((card) => (
        <CardContainer
          className="p-6 flex-1"
          variant={CardContainerVariant.SECONDARY}
          key={card.key}
        >
          <Disclosure key={card.key}>
            {({ close }) => (
              <DisclosureContent
                close={close}
                selected={card.key === selectedCard}
                card={card}
                onDisclosureClick={() => {
                  if (card.key !== selectedCard) {
                    setSelectedCard(card.key);
                    return;
                  }
                  setSelectedCard(null);
                }}
              />
            )}
          </Disclosure>
        </CardContainer>
      ))}
    </div>
  );
}
