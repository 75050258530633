import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { SuccessPopup } from "../../popups/AnimatedPopup";
import { popupState } from "../../recoil/page";
import { PopupType } from "../../utils/enums";

export default function PopupConductor() {
  const currentPopupState = useAtomValue(popupState);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  useEffect(() => {
    if (currentPopupState?.name === PopupType.SUCCESS) {
      setShowSuccessPopup(true);
    } else {
      setShowSuccessPopup(false);
    }
  }, [currentPopupState]);

  return (
    <SuccessPopup
      className={[
        currentPopupState?.analyticsClassName,
        currentPopupState?.className,
      ].join(" ")}
      type={currentPopupState?.type}
      show={showSuccessPopup}
      setShow={setShowSuccessPopup}
      durationSeconds={currentPopupState?.durationSeconds}
      onClose={currentPopupState?.onClose}
    >
      {currentPopupState?.children}
    </SuccessPopup>
  );
}
