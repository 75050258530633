import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import clsx from "clsx";
import type { MouseEvent, MouseEventHandler } from "react";

import { Badge, Typography } from "../../../library";
import BookmarkLink from "../../../library/BookmarkLink";
import { withTooltip } from "../../../library/Tooltip";
import type { Bookmark, OCRFile } from "../../../shared/types";
import { borderColorClass } from "../../../utils/colors";
import { getCleanedFileName } from "./utils";

const ICON_CLASSNAMES =
  "flex items-center justify-center hover:bg-cp-neutral-palette-200 rounded-3 cursor-pointer";

export type HandleClickBookmarkOptions = {
  file: OCRFile;
  bookmark: Bookmark;
};

const DownloadButton = withTooltip(
  ({
    disabled,
    onClick,
  }: { disabled: boolean; onClick: MouseEventHandler }) => {
    return (
      <Badge
        as="button"
        Icon={FileDownloadOutlinedIcon}
        size="sm-md"
        iconClass="text-cp-neutral-palette-600 ml-1"
        className={clsx(
          "analytics-download-file-from-sidebar p-1",
          ICON_CLASSNAMES
        )}
        onClick={onClick}
        disabled={disabled}
      />
    );
  },
  { className: "w-48", placement: "bottom-start" }
);

interface FileItemProps {
  file: OCRFile;
  contractNumber?: string;
  selected?: boolean;
  handleClickBookmark: ({ file, bookmark }: HandleClickBookmarkOptions) => void;
  onClick: (file: OCRFile) => void;
  onDownload: (file: OCRFile) => void;
  className?: string;
}

export function FileItem({
  file,
  contractNumber,
  selected = false,
  handleClickBookmark,
  onClick,
  onDownload,
  className,
}: FileItemProps) {
  function handleDownload({
    event,
    file,
  }: {
    event: MouseEvent;
    file: OCRFile;
  }) {
    event.stopPropagation();
    onDownload(file);
  }

  const cleanedFileName = getCleanedFileName(file, contractNumber);
  return (
    <>
      <div className={clsx("flex w-full items-center gap-3", className)}>
        <Typography
          className="cursor-pointer break-words hover:underline line-clamp-1"
          emphasis={selected}
          color="brand.bold.enabled"
          size="sm"
          onClick={() => onClick(file)}
          title={cleanedFileName}
        >
          {cleanedFileName}
        </Typography>
        <div className="flex">
          <DownloadButton
            tooltip="Download this document"
            disabled={!file.has_access}
            onClick={(event) => handleDownload({ event, file })}
          />
        </div>
      </div>
      {!!file.bookmarks.length && (
        <div className="grid gap-1">
          {file.bookmarks.map((bookmark: Bookmark) => (
            <div key={bookmark.id} className="flex items-center">
              <BookmarkLink
                onClick={() => handleClickBookmark({ file, bookmark })}
                label={`Jump to ${bookmark.bookmark_type.toLowerCase()}`}
                background
              />
            </div>
          ))}
        </div>
      )}
      <hr className={borderColorClass.neutral.subtle.enabled} />
    </>
  );
}
