// TODO: For now these variables exists as separate atoms for backwards compatibility with pre-existing cookies.
import Cookies from "js-cookie";
import _isEmpty from "lodash/isEmpty";
import type { AtomEffect } from "recoil";

import { createJSONStorage } from "jotai/utils";
import {
  COOKIE_NO_EXPIRE,
  browserLocalStorage,
  browserSessionStorage,
  hasWindow,
} from "../utils";

/**
 * `createSessionStorage` creates a typed `storage` object that directs
 * Jotai's `atomWithStorage` utility to persist data to sessionStorage.
 * `atomWithStorage` persists data in localStorage by default.
 *
 * @template T - The type of data to be stored in the session storage.
 * @returns A session storage object.
 */
export function createSessionStorage<T>() {
  return createJSONStorage<T>(() =>
    // Check for "window" to handle SSR. If there's no window, pass a mock
    // storage to allow SSR initialization.
    hasWindow()
      ? window.sessionStorage
      : {
          getItem: (_key) => "",
          setItem: (_key, _newValue) => {},
          removeItem: (_key) => {},
        }
  );
}

export function isStorableValue<T>(value: T) {
  return !_isEmpty(value) || ["boolean", "number"].includes(typeof value);
}

type GetCookieEffectOptions<T> = {
  serialize: (value: T) => string;
  deserialize: (value: string) => T;
  defaultValue: string;
};

/**
 * getCookieEffect creates an atom effect that instantiates the atom with a cookie
 * value for the given key, and results in write-through for state changes.
 *
 * Note: It does not subscribe to cookie changes, so if we write to the cookie
 * after page load outside of recoil, the states will be out of sync.
 *
 * @param key
 * @param options: {serialize: (any) => any, deserialize: (any) => any, defaultValue: any}
 * @returns {function({node: *, onSet: *, setSelf: *, trigger: 'get'|'set'}): void}
 */
export function getCookieEffect<T = string>(
  key: string,
  options: GetCookieEffectOptions<T> = {
    serialize: (value) => `${value}`,
    deserialize: (value) => value as T,
    defaultValue: "",
  }
): AtomEffect<T> {
  const { serialize, deserialize, defaultValue } = options;
  return ({ onSet, setSelf, trigger }) => {
    // Initialize atom with stored value in cookie IF there is no default value.
    if (trigger === "get") {
      const storedValue = Cookies.get(key) || defaultValue;
      // Due to bugs, it's possible the string "undefined" may have gotten saved.
      if (isStorableValue(storedValue) && storedValue !== "undefined") {
        setSelf(deserialize(storedValue));
      } else {
        Cookies.remove(key);
      }
    }

    // Store atom values in cookie.
    onSet((newValue, _oldValue, isReset) => {
      // Don't serialize data if it was initialized using the atom's default value.
      if (isReset) {
        Cookies.remove(key);
      }
      if (isStorableValue(newValue)) {
        Cookies.set(key, serialize(newValue), {
          expires: COOKIE_NO_EXPIRE,
        });
      }
    });
  };
}

/**
 * getLocalStorageEffect creates an atom effect that instantiates the atom with a local storage
 * value for the given key, and results in write-through for state changes.
 *
 * Note: It does not subscribe to localstorage changes, so if we write to local storage
 * after page load outside of recoil, the states will be out of sync.
 *
 * @param key
 * @param sessionOnly - whether to use sessionStorage or localStorage
 * @returns {function({node: *, onSet: *, setSelf: *, trigger: 'get'|'set'}): void}
 */
export function getLocalStorageEffect<T>(
  key: string,
  sessionOnly = false
): AtomEffect<T> {
  const browserStorage = sessionOnly
    ? browserSessionStorage
    : browserLocalStorage;
  return ({ onSet, setSelf, trigger }) => {
    // Initialize atom with stored value in localStorage IF there is no default value.
    if (trigger === "get") {
      const storedValue = browserStorage.get(key);
      if (storedValue !== null) {
        setSelf(storedValue);
      }
    }

    // Store atom values in localStorage.
    onSet((newValue, _, isReset) => {
      // Don't serialize data if it was initialized using the atom's default value.
      if (isReset) {
        browserStorage.remove(key);
      }
      if (newValue !== undefined) {
        browserStorage.set(key, newValue);
      }
    });
  };
}
