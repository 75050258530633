import { useState } from "react";
import { useSetRecoilState } from "recoil";

import { isFreshSignupAtom } from "../../recoil/history";
import Modal, { modalSizes } from "../../shared/Modal/Modal";
import { getParams } from "../../utils";
import { accountModals, modalTriggerURLParams } from "../../utils/enums";

import { useSetAtom } from "jotai";
import useShowSignupSurvey from "../../hooks/useShowSignupSurvey";
import { modalState } from "../../recoil/page";
import CompleteBuyerAccountForm from "./CompleteBuyerAccountForm";
import { type InferredBLA, WindowType } from "./types";

interface BuyerSignupModalProps {
  hideModal: () => void;
  onComplete: () => void;
  isBlocking?: boolean;
  goBack?: () => void;
  inferredBLA?: Maybe<InferredBLA>;
}

function getTitle(invite: string | undefined, showConfirmation: boolean) {
  if (showConfirmation) {
    return {
      title: "Are you a supplier?",
      subtitle:
        "It seems like you may be a supplier! With a supplier account, you can provide key information about your business to help us match your contracts with relevant searches.",
    };
  }
  return {
    title: invite ? "Complete your profile" : "Welcome to Pavilion!",
    subtitle:
      "We just need a little bit more information before we can create your account. This allows us to give you a tailored experience on Pavilion.",
  };
}

export default function BuyerSignupModal({
  hideModal,
  onComplete,
  goBack,
  isBlocking = false,
  inferredBLA = null,
}: BuyerSignupModalProps) {
  const setCurrentModal = useSetRecoilState(modalState);
  const invite = getParams()[modalTriggerURLParams.INVITE]?.toString();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { title, subtitle } = getTitle(invite, showConfirmation);
  const setIsFreshSignup = useSetAtom(isFreshSignupAtom);
  const showSignupSurvey = useShowSignupSurvey();

  function goToBuyerSignupWelcome() {
    setIsFreshSignup(false);
    setCurrentModal({
      name: accountModals.BUYER_SIGNUP_WELCOME,
      onComplete,
    });
  }

  // Once user has completed the signup step, their account is created.
  // Hence, this modal should be blocking and user should be forced to trigger a refresh via "Skip"
  function goToBuyerSurveyModal() {
    showSignupSurvey({ onComplete: goToBuyerSignupWelcome });
  }

  return (
    <Modal
      hideModal={hideModal}
      isBlocking={isBlocking}
      className="analytics-signup-modal"
      // Allow scrolling on smaller screens so user can select dropdown items
      contentClassName="no-scrollbar"
      title={title}
      subtitle={subtitle}
      modalSize={showConfirmation ? modalSizes.DEFAULT : modalSizes.SMALL}
      showLogoHeader={false}
      goBack={goBack}
    >
      <CompleteBuyerAccountForm
        hideModal={hideModal}
        onComplete={goToBuyerSurveyModal}
        inferredBLA={inferredBLA}
        onChangeConfirmation={setShowConfirmation}
        parentWindow={WindowType.Modal}
      />
    </Modal>
  );
}
