import { atomWithStorage } from "jotai/utils";

import { isFreshSEOUser } from "../utils/tracking";
import { createSessionStorage } from "./util";

export const isFreshSEOUserAtom = atomWithStorage<boolean>(
  "isFreshSEOUserState",
  isFreshSEOUser(),
  undefined,
  { getOnInit: true }
);

const isFreshSignupSessionStorage = createSessionStorage<boolean>();
export const isFreshSignupAtom = atomWithStorage(
  "isFreshSignup",
  false,
  isFreshSignupSessionStorage,
  { getOnInit: true }
);

const hasSeenProjectPromptSessionStorage = createSessionStorage<boolean>();
export const hasSeenProjectPromptAtom = atomWithStorage(
  "hasSeenProjectPromptState",
  false,
  hasSeenProjectPromptSessionStorage,
  { getOnInit: true }
);

export const optedInOutreachSuppliersAtom = atomWithStorage<number[]>(
  "optedInOutreachSuppliers",
  [],
  undefined,
  { getOnInit: true }
);

const hasDismissedPurchasingGuideBannerSessionStorage =
  createSessionStorage<boolean>();
export const hasDismissedPurchasingGuideBannerAtom = atomWithStorage(
  "hasDismissedPurchasingGuideBanner",
  false,
  hasDismissedPurchasingGuideBannerSessionStorage,
  { getOnInit: true }
);
