import DescriptionOutlined from "@mui/icons-material/DescriptionOutlined";
import NoteAltOutlined from "@mui/icons-material/NoteAltOutlined";
import SettingsOutlined from "@mui/icons-material/SettingsOutlined";
import { useRecoilValue } from "recoil";

import useShowModal from "../../../hooks/useShowModal";
import useSupplierEditUrl from "../../../hooks/useSupplierEditUrl";
import { Button, ButtonSizes, Link, PageSection } from "../../../library";
import {
  supplierExpiredAgreementsState,
  userState,
} from "../../../recoil/user";
import { accountModals } from "../../../utils/enums";
import { supplierHasFeature } from "../../../utils/featureManagement";

import ExpiredAgreementsAccordion from "../SupplierWelcomePage/ExpiredAgreementsAccordion";
import SupplierFAQ from "../SupplierWelcomePage/SupplierFAQ";
import ProSupplierWelcomeTasks from "./ProSupplierWelcomeTasks";
import SupplierAnalytics from "./SupplierAnalytics";

export default function OldProSupplierWelcomePage() {
  const supplierEditUrl = useSupplierEditUrl();
  const { supplier, supplierProfile } = useRecoilValue(userState);
  const expiredAgreements = useRecoilValue(supplierExpiredAgreementsState);

  // Show analytics for verified, approved users who have signed an annual views agreement.
  const shouldShowAnalytics = supplierHasFeature(
    supplier?.activeAgreements,
    "viewAnalytics"
  );

  const showSetPasswordModal = useShowModal(accountModals.SET_PASSWORD);
  if (supplierProfile?.needPasswordChange) {
    showSetPasswordModal({});
  }

  return (
    <div className="flex flex-col gap-8">
      <PageSection>
        <div className="flex flex-col lg:flex-row gap-x-32 gap-y-10 mt-8">
          <div className="flex-none flex flex-col gap-3">
            <Button
              theme={Button.themes.TERTIARY_DARK}
              className="!justify-start analytics-supplier-manage-contracts-upload-cta"
              size={ButtonSizes.SMALL}
              as={Link}
              linkProps={{
                href: `/suppliers/${supplier?.handle}/upload-contracts`,
                underline: false,
                newWindow: false,
              }}
              badgeProps={{
                Icon: DescriptionOutlined,
              }}
            >
              Upload Contracts
            </Button>
            <Button
              theme={Button.themes.TERTIARY_DARK}
              className="!justify-start analytics-supplier-welcome-page-manage-preferences-cta"
              size={ButtonSizes.SMALL}
              as={Link}
              linkProps={{
                href: "/profile",
                underline: false,
                newWindow: false,
              }}
              badgeProps={{
                Icon: SettingsOutlined,
              }}
            >
              Manage Preferences
            </Button>
            {supplierEditUrl && (
              <Button
                theme={Button.themes.TERTIARY_DARK}
                className="!justify-start analytics-supplier-welcome-page-edit-profile-cta"
                as={Link}
                linkProps={{
                  href: supplierEditUrl,
                  underline: false,
                  newWindow: false,
                }}
                size={ButtonSizes.SMALL}
                badgeProps={{
                  Icon: NoteAltOutlined,
                }}
              >
                Edit business profile
              </Button>
            )}
          </div>
          <div className="flex-grow grid gap-6">
            {shouldShowAnalytics && (
              <div className="col-span-full">
                <SupplierAnalytics />
              </div>
            )}
            {!!expiredAgreements.length && <ExpiredAgreementsAccordion />}
            <ProSupplierWelcomeTasks />
          </div>
        </div>
      </PageSection>
      <PageSection className="bg-cp-violet-100 py-12 flex-grow">
        <SupplierFAQ />
      </PageSection>
    </div>
  );
}
