import clsx from "clsx";
import type { ReactNode } from "react";

import {
  CardContainer,
  CardContainerVariant,
  Typography,
} from "../../../library";
import type { SupplierContact } from "../../../shared/types";
import { borderColorClass } from "../../../utils/colors";
import { elevationClass } from "../../../utils/designTokens";
import { supplierHasFeature } from "../../../utils/featureManagement";
import { useDedupedSupplierContacts } from "../SupplierEditForm/SupplierEditSection/helpers";
import ContractCatalogCTA from "./ContractCatalogCTA";
import NoDocumentsCTA from "./NoDocumentsCTA";
import SupplierCTA from "./SupplierCTA";
import SupplierContactGuarantee from "./SupplierContactGuarantee";
import SupplierContactInfo from "./SupplierContactInfo";

interface SupplierCTAContainerProps {
  id: number;
  name: string;
  activeAgreements?: string[];
  handle: string;
  solicitationId?: Maybe<string>;
  contractId?: string;
  trackClick: () => void;
  showNoDocuments?: boolean;
  children?: ReactNode;
  supplierContacts?: SupplierContact[];
  hasConfirmedContact?: boolean;
  contractCatalogUrl?: Maybe<string>;
}

function ResponseTimeCallout() {
  return (
    <div className="flex flex-row">
      <div className="pr-4 border-r border-solid">
        <Typography color="neutral.bolder.enabled" variant="meta" size="sm">
          Response time
        </Typography>
        <Typography color="feedback.bold.success" emphasis>
          Under 24 hrs
        </Typography>
      </div>
      <div className="ml-4">
        <Typography color="neutral.bolder.enabled" variant="meta" size="sm">
          Response rate
        </Typography>
        <Typography color="feedback.bold.success" emphasis>
          100%
        </Typography>
      </div>
    </div>
  );
}

export default function SupplierCTAContainer({
  id,
  name,
  activeAgreements,
  solicitationId,
  handle,
  contractId,
  showNoDocuments,
  children,
  trackClick,
  supplierContacts,
  hasConfirmedContact,
  contractCatalogUrl,
}: SupplierCTAContainerProps) {
  const isSolicitation = !!(solicitationId && contractId);
  const showResponseTime = supplierHasFeature(
    activeAgreements,
    "contactResponseTag"
  );
  const dedupedContacts = useDedupedSupplierContacts(name, supplierContacts);
  const showContractCatalogCTA = !!contractCatalogUrl && !!contractId;
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-6">
      {showContractCatalogCTA && (
        <ContractCatalogCTA
          contractCatalogUrl={contractCatalogUrl}
          contractId={contractId}
          supplierId={id}
        />
      )}
      <CardContainer
        variant={CardContainerVariant.SECONDARY}
        className={clsx("p-6 gap-5 w-full md:max-w-108 rounded-6 self-start", {
          [elevationClass["elevation-1"]]: !showContractCatalogCTA,
        })}
      >
        <div className="flex flex-col gap-2">
          <Typography
            variant="headline"
            size="sm"
            color="brand.default.secondary.enabled"
            emphasis
          >
            Ask questions or request a quote
          </Typography>
          {showResponseTime && <ResponseTimeCallout />}
        </div>
        <SupplierCTA
          handle={handle}
          name={name}
          solicitationId={solicitationId}
          contractId={contractId}
          hasConfirmedContact={hasConfirmedContact}
          hasContractCatalog={!!contractCatalogUrl}
          trackClick={trackClick}
        />
        {!!dedupedContacts?.length && (
          <SupplierContactInfo
            supplierId={id}
            supplierName={name}
            dedupedContacts={dedupedContacts}
          />
        )}
        <hr className={borderColorClass.neutral.default} />
        <SupplierContactGuarantee />
      </CardContainer>
      {isSolicitation && showNoDocuments && (
        <NoDocumentsCTA
          solicitationId={solicitationId}
          contractId={contractId}
        />
      )}
      {children}
    </div>
  );
}
